import { createRouter, createWebHistory } from 'vue-router'

// Checklist for adding a new view:
// - [ ] Create a component in the views folder
// - [ ] Create an entry in the router index using that component
// - [ ] Add an entry with the required user permissions in the core/routes.js file
// - [ ] Add an entry in GlobalSidebar
// - [ ] Add a header in GlobalHeader

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/workspace/:workspace_id',
      name: 'home',
      component: () => import('@/views/HomePage.vue')
    },
    {
      path: '/workspace/:workspace_id/search/:tab?',
      name: 'search',
      component: () => import('@/views/Search.vue')
    },
    {
      path: '/workspace/:workspace_id/meetings',
      component: () => import('@/views/MeetingWrapper.vue'),
      children: [
        {
          path: '',
          name: 'meetings',
          component: () => import('@c/meetings/Meetings.vue')
        },
        {
          path: ':meeting_id/outputs',
          name: 'meeting-outputs',
          component: () => import('@c/meetings/MeetingOutputs.vue')
        }
      ]
    },
    {
      path: '/workspace/:workspace_id/chat/:chat_id?',
      name: 'chat',
      component: () => import('@/views/Chat.vue')
    },
    {
      path: '/workspace/:workspace_id/portfolio/:ori_type',
      component: () => import('@/views/Portfolio.vue'),
      children: [
        {
          path: '',
          name: 'portfolio',
          component: () => import('@/views/Portfolio.vue')
        },
        {
          path: ':ori_id',
          name: 'portfolio-detail',
          component: () => import('@/views/Portfolio.vue')
        }
      ]
    },
    {
      path: '/workspace/:workspace_id/prospecting',
      name: 'prospecting',
      component: () => import('@/views/Prospecting.vue')
    },
    {
      path: '/workspace/:workspace_id/business-development/:detail*',
      name: 'bizdev',
      component: () => import('@/views/BizDev.vue')
    },
    // {
    //   path: '/workspace/:workspace_id/lists',
    //   name: 'lists',
    //   component: () => import('@/views/AccountsListOverview.vue')
    // },
    {
      path: '/workspace/:workspace_id/presentations',
      name: 'presentations',
      component: () => import('@/views/Collections.vue')
    },
    {
      path: '/workspace/:workspace_id/analytics',
      name: 'analytics',
      component: () => import('@/views/Analytics.vue')
    },
    {
      path: '/workspace/:workspace_id/curate',
      name: 'curation',
      component: () => import('@/views/Curation.vue')
    },
    {
      path: '/workspace/:workspace_id/settings/:tab*',
      name: 'settings',
      component: () => import('@/views/Settings.vue')
    },
    {
      path: '/workspace/:workspace_id/welcome',
      name: 'onboarding',
      component: () => import('@/views/Onboarding.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        authentication: false
      }
    },
    {
      path: '/signup',
      name: 'register',
      component: () => import('@/views/Login.vue'),
      meta: {
        authentication: false
      }
    },
    {
      path: '/oauth2/callback/google',
      name: 'googleOauth',
      component: () => import('@/views/AuthenticateSSO.vue'),
      meta: {
        authentication: false
      }
    },
    {
      path: '/oauth2/callback/microsoft',
      name: 'microsoftOauth',
      component: () => import('@/views/AuthenticateSSO.vue'),
      meta: {
        authentication: false
      }
    },
    {
      path: '/integrations/authenticate/:integration',
      name: 'integrationOauth',
      component: () => import('@/views/IntegrationAuth.vue')
    },
    {
      path: '/:foundPath(.*)',
      redirect: { name: 'root' }
    },
    {
      path: '/',
      name: 'root'
    }
  ]
})

export default router
