<template>
  <div v-if="headerTitle" class="app-uman-header">
    <p class="app-uman-header-title">{{ headerTitle }}</p>
    <GlobalSearchbar v-if="showSearchbar" class="app-uman-header-search" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useUserStore } from '@/stores/user'

import GlobalSearchbar from './GlobalSearchbar.vue'

const route = useRoute()

const headerTitle = computed(() => {
  return {
    search: 'Search',
    meetings: 'Your meetings',
    portfolio: 'Portfolio',
    prospecting: 'Prospecting',
    bizdev: 'Business Development',
    lists: 'Account lists',
    presentations: 'Presentations',
    analytics: 'Analytics',
    curation: 'Curation',
    settings: 'Settings',
    chat: 'PortfolioGPT'
  }[route.name]
})

const user = useUserStore()

const showSearchbar = computed(() => {
  return (
    ['search', 'portfolio'].includes(route.name) &&
    (user.canReadOri || user.canWriteOri || user.canUseSearch)
  )
})
</script>

<style lang="scss" scoped>
.app-uman-header {
  height: 7.5rem;
  min-height: 7.5rem;
  padding: 0 2.5rem;
  background: linear-gradient(
    94deg,
    rgba($primary, 0.06) -0.54%,
    rgba($pink, 0.15) 89.06%
  );
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &-title {
    background: linear-gradient(
      270deg,
      $dark 0.24%,
      rgba($primary, 0.75) 112.49%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &-search {
    flex: 1;
    margin-left: 2.5rem;
  }
}
</style>
