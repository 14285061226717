<template>
  <div class="app-uman" :class="{ 'has-sidebar': showSidebar }">
    <GlobalSidebar v-if="showSidebar" />
    <div class="app-uman-content">
      <GlobalHeader />
      <router-view v-if="showAppContent" />
      <TaskQueue ref="umantasks" class="app-uman-queue" />
    </div>
    <Toast ref="umantoast" />
    <Snackbar ref="umansnackbar" />
  </div>
</template>

<script setup>
import GlobalHeader from '@c/core/GlobalHeader.vue'
import GlobalSidebar from '@c/core/GlobalSidebar.vue'

import moment from 'moment'
import Console from '@/core/console'

import { useRoute } from 'vue-router'
import { useIntercom } from './core/intercom'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { ref, provide, computed } from 'vue'
import { useTryCatch } from './core/trycatch'
import TaskQueue from './components/core/TaskQueue.vue'

const auth = useAuthStore()

const umantoast = ref(null)
const toast = {
  danger: (title, description, time) =>
    umantoast.value.danger(title, description, time),
  success: (title, description, time) =>
    umantoast.value.success(title, description, time),
  info: (title, description, time) =>
    umantoast.value.info(title, description, time),
  error(e, context) {
    if ([400, 404].includes(e.status) && !!e.data.detail)
      umantoast.value.danger(`Error ${context}`, e.data.detail)
    else
      umantoast.value.danger(
        `Error ${context}`,
        `Something went wrong while ${context}. Please try again later or contact support.`
      )
  }
}
provide('$toast', toast)

const umansnackbar = ref(null)
const snackbar = {
  show: (args) => umansnackbar.value.show(args)
}

provide('$snackbar', snackbar)

const umantasks = ref(null)
const taskQueue = {
  tasks: computed(() => umantasks.value.tasks),
  addTask: (task) => umantasks.value.addTask(task),
  subscribe: (task, callbacks) => umantasks.value.subscribe(task, callbacks),
  latestTasksCompleted: computed(() => umantasks.value.latestTasksCompleted),
  openModal: () => umantasks.value.openModal()
}

provide('$queue', taskQueue)

provide('$moment', moment)

provide('$console', Console)

const { trycatch } = useTryCatch(console, toast)

provide('trycatch', trycatch)

const route = useRoute()

const user = useUserStore()
const workspace = useWorkspaceStore()

const showAppContent = computed(() => {
  return (
    !!route.name &&
    (!route.meta.authentication ||
      (route.name === 'integrationOauth'
        ? auth.authenticated
        : auth.authenticated &&
          !!workspace.currentWorkspace &&
          workspace.currentWorkspace.uuid === route.params.workspace_id &&
          !!user.currentWorkspaceMember))
  )
})

const showSidebar = computed(() => {
  return (
    showAppContent.value &&
    route.meta.authentication &&
    !['integrationOauth', 'onboarding'].includes(route.name)
  )
})

if (import.meta.env.PROD) useIntercom()
</script>

<style lang="scss">
@use './index.scss';

html,
body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  position: relative;
  overflow: hidden;
}

* {
  font-family: 'Nunito Sans', sans-serif;
}

a {
  text-decoration: none;
  color: initial;

  &:hover {
    color: initial;
  }
}

code {
  background: $grey-200;
  border-left: solid 4px $grey;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: block;
  margin: 0.5rem 0;
  white-space: pre-wrap;
  font-family: monospace;
}

.app-uman {
  display: grid;
  grid-template-columns: 1fr;
  background: $white;

  &.has-sidebar {
    grid-template-columns: auto 1fr;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
  }

  &-queue {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 8;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba($black, 0.4) transparent !important;
}
</style>
