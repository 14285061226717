<template>
  <div class="task-queue">
    <div
      v-if="visibleTasks.length > latestTasks.length"
      class="task-queue-item"
      @click="openModal"
    >
      <div class="task-queue-item-side"></div>
      <div class="task-queue-item-content">
        <div class="task-queue-item-text-wrapper">
          <p class="task-queue-item-text">
            {{ visibleTasks.length - latestTasks.length }} more new task{{
              visibleTasks.length - latestTasks.length === 1 ? '' : 's'
            }}
          </p>
        </div>
        <Icon name="chevron-right-small" class="task-queue-item-icon" />
      </div>
    </div>
    <TransitionGroup name="tasklist">
      <div
        v-for="task in latestTasks"
        :key="`uman-tasks-${task.id}`"
        class="task-queue-item"
        @click="openModal"
      >
        <div class="task-queue-item-side"></div>
        <div class="task-queue-item-content">
          <Icon
            :name="iconName(task)"
            :fill="iconFill(task)"
            class="task-queue-item-icon"
          />
          <div class="task-queue-item-text-wrapper">
            <p v-if="!task.finished" class="task-queue-item-text">
              {{ task.loading }}
            </p>
            <p v-else-if="task.errored" class="task-queue-item-text">
              {{ task.error }}
            </p>
            <p v-else class="task-queue-item-text">
              {{ task.done(task.result) }}
            </p>
          </div>
          <div v-if="task.finished && !task.errored" @click.stop>
            <Button
              v-if="task.cta(task.result)"
              :text="task.cta(task.result)"
              size="xs"
              type="light"
              @click="() => task.callback(task.result)"
            />
          </div>
          <div @click.stop>
            <Button
              icon="close"
              size="xs"
              type="grey"
              @click="() => removeTask(task)"
            />
          </div>
        </div>
      </div>
    </TransitionGroup>
    <TaskQueueModal
      v-if="showModal"
      :visible="showModal"
      :tasks="tasks"
      @close="() => (showModal = false)"
      @remove="removeTask"
    />
  </div>
</template>

<script setup>
import TaskQueueModal from './tasks/TaskQueueModal.vue'

import { computed, inject, ref } from 'vue'

const tasks = ref([])

const hiddenTasks = ref([])
const visibleTasks = computed(() =>
  tasks.value.filter((t) => !hiddenTasks.value.includes(t.id))
)

const latestTasks = computed(() => visibleTasks.value.slice(-3))

const showModal = ref(false)

const console = inject('$console')

function getNewTaskId() {
  const maxId = tasks.value.reduce(
    (acc, curr) =>
      curr.id.startsWith('task-') ? parseInt(curr.id.replace('task-', '')) : acc,
    0
  )
  return `task-${maxId + 1}`
}

async function addTask(task) {
  const id = task.id || getNewTaskId()

  tasks.value.push({
    ...task,
    id,
    result: undefined,
    finished: false,
    errored: null,
    onComplete: [],
    onError: []
  })
  try {
    const res = await task.run()
    tasks.value = tasks.value.map((t) =>
      t.id === id
        ? {
            ...t,
            result: res,
            finished: true
          }
        : t
    )
    tasks.value.find((t) => t.id === id).onComplete.forEach((cb) => cb(res))
    return res
  } catch (error) {
    tasks.value = tasks.value.map((t) =>
      t.id === id
        ? {
            ...t,
            errored: error,
            finished: true
          }
        : t
    )
    tasks.value.find((t) => t.id === id).onError.forEach((cb) => cb(error))
    console.debug('Error running task', error)
  } finally {
    const t = tasks.value.find((t) => t.id === id)
    tasks.value = tasks.value.filter((t) => t.id !== id)
    tasks.value.push(t)
    hiddenTasks.value = hiddenTasks.value.filter((t) => t !== id)
    latestTasksCompleted.value++
  }
}

function removeTask(task) {
  hiddenTasks.value.push(task.id)
}

function subscribe(id, { onComplete = undefined, onError = undefined }) {
  const task = tasks.value.find((t) => t.id === id)
  if (!task) return

  if (onComplete) task.onComplete.push(onComplete)
  if (onError) task.onError.push(onError)
  tasks.value = tasks.value.map((t) => (t.id === id ? task : t))
  return task
}

const latestTasksCompleted = ref(0)

function openModal() {
  showModal.value = true
  latestTasksCompleted.value = 0
}

defineExpose({
  addTask,
  subscribe,
  tasks,
  latestTasksCompleted,
  openModal
})

function iconName(task) {
  return task.finished
    ? task.errored
      ? 'check-warning'
      : 'check-circle'
    : 'refresh'
}

function iconFill(task) {
  return task.finished ? (task.errored ? 'red' : 'green') : 'primary'
}
</script>

<style lang="scss" scoped>
.task-queue {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    overflow: hidden;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba($black, 0.5);
    min-width: 30rem;
    max-width: 30rem;
    height: 3rem;
    cursor: pointer;

    &-side {
      height: 100%;
      min-width: 0.5rem;
      width: 0.5rem;
      background: $primary;
    }

    &-content {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
      flex: 1;
      padding: 0.5rem;
      min-width: 0;
    }

    &-text {
      &-wrapper {
        flex: 1;
        min-width: 0;
      }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    &-icon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}

// Transition group styles
.tasklist-move,
.tasklist-enter-active,
.tasklist-leave-active {
  transition: all 0.5s ease;
}

.tasklist-enter-from,
.tasklist-leave-to {
  opacity: 0;
  transform: translateX(-2.5rem);
}

.tasklist-leave-active {
  position: absolute;
}
</style>
