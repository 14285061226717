<template>
  <div
    class="uloader"
    :class="{
      [size]: true,
      overlay,
      'full-width': fullWidth,
      centered: fullWidth || overlay
    }"
    :style="{
      ...(height ? { height, minHeight: height } : {}),
      ...backgroundColor
    }"
  >
    <Spinner v-if="type === 'spinner'" :size="size" :color="color" />
    <div
      v-else
      class="uloader-dots"
      :class="{ [type]: true }"
      :style="dotsStyle"
    >
      <div class="uloader-dots-dot"></div>
      <div class="uloader-dots-dot"></div>
      <div class="uloader-dots-dot"></div>
    </div>
  </div>
</template>

<script setup>
import Spinner from '@l/loader/Spinner.vue'

import { computed } from 'vue'
import { mapColor } from '@/core/colors'

const props = defineProps({
  size: {
    type: String,
    default: 'm',
    validator: (s) => ['xs', 's', 'm', 'l', 'xl'].includes(s)
  },
  type: {
    type: String,
    default: 'spinner',
    validator: (value) => ['spinner', 'dots', 'wave'].includes(value)
  },
  color: {
    type: String,
    default: 'grey'
  },
  overlay: {
    type: Boolean,
    default: false
  },
  background: {
    type: String,
    default: ''
  },
  fullWidth: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: ''
  }
})

const fillColor = computed(() => mapColor(props.color))

const dotsStyle = computed(() => ({
  '--dot-color': fillColor.value
}))

const backgroundColor = computed(() => {
  const c = mapColor(props.background) || ''
  return c ? { background: `${c} !important` } : {}
})
</script>

<style lang="scss" scoped>
.uloader {
  width: fit-content;

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: rgba($white, 0.5);
  }

  &.full-width {
    width: 100%;
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-dots {
    height: 1rem;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.3rem;
    width: fit-content;
    display: inline-flex;

    &.dots {
      align-items: center;
      & > .uloader-dots-dot {
        animation-name: flicker;
      }
    }

    &.wave {
      align-items: flex-end;
      & > .uloader-dots-dot {
        animation-name: bounce;
      }
    }

    &-dot {
      height: 3px;
      width: 3px;
      border-radius: 100%;
      background: var(--dot-color);
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      &:nth-of-type(2) {
        animation-delay: 150ms;
      }

      &:nth-of-type(3) {
        animation-delay: 300ms;
      }
    }
  }

  &.xs {
    & .uloader-spinner {
      height: 1rem;
      width: 1rem;
    }

    & .uloader-dots {
      height: 1rem;
      width: 1rem;

      &-dot {
        height: 3px;
        width: 3px;
      }
    }
  }

  &.s {
    & .uloader-spinner {
      height: 1.2rem;
      width: 1.2rem;
    }

    & .uloader-dots {
      height: 1.2rem;
      width: 1.2rem;

      &-dot {
        height: 3px;
        width: 3px;
      }
    }
  }

  &.m {
    & .uloader-spinner {
      height: 1.5rem;
      width: 1.5rem;
    }

    & .uloader-dots {
      height: 1.5rem;
      width: 1.5rem;

      &-dot {
        height: 4px;
        width: 4px;
      }
    }
  }

  &.l {
    & .uloader-spinner {
      height: 1.85rem;
      width: 1.85rem;
    }

    & .uloader-dots {
      height: 1.85rem;
      width: 1.85rem;

      &-dot {
        height: 5px;
        width: 5px;
      }
    }
  }

  &.xl {
    & .uloader-spinner {
      height: 2.15rem;
      width: 2.15rem;
    }

    & .uloader-dots {
      height: 2.15rem;
      width: 2.15rem;

      &-dot {
        height: 6px;
        width: 6px;
      }
    }
  }
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-4px);
  }
}

@keyframes flicker {
  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}
</style>
