<template>
  <Modal
    title="Set up your workspace"
    subtitle="Configure your new workspace with essential information to get started quickly"
    width="75rem"
    :visible="visible"
    :padded="false"
    :show-cancel="currentStep !== 0"
    cancel-text="Previous"
    :submit-text="
      currentStep === steps.length - 1 ? 'Create workspace' : 'Next'
    "
    :on-cancel="prev"
    :disabled="disableNext"
    @submit="next"
    @close="close"
  >
    <div class="ws-create">
      <div class="ws-create-sidebar">
        <div
          v-for="(step, idx) in steps"
          :key="`ws-create-${step.key}`"
          class="ws-create-sidebar-item"
          :class="{ selected: currentStep === idx, done: currentStep > idx }"
          @click="() => selectStep(idx)"
        >
          <div class="ws-create-sidebar-item-count">
            <span
              v-if="currentStep <= idx"
              class="ws-create-sidebar-item-count-content"
              >{{ idx + 1 }}</span
            >
            <Icon
              v-else
              name="check-medium"
              fill="white"
              class="ws-create-sidebar-item-count-content"
            />
          </div>
          <p class="ws-create-sidebar-item-title">
            {{ step.title }}
          </p>
        </div>
      </div>
      <div class="ws-create-content">
        <div v-if="currentStep === 0" class="ws-create-content-step">
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              Organisation
              <span class="optional">(optional)</span>
            </p>
            <OrganisationSearch
              :organisation="organisation"
              :create-account="false"
              :allow-custom="false"
              @input="selectOrganisation"
              @remove="removeOrganisation"
            />
          </div>
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              Workspace name
              <span class="required">*</span>
            </p>
            <TextInput
              v-model="name"
              placeholder="Enter a name for your workspace"
            />
          </div>
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              File storage integration
              <span class="required">*</span>
            </p>
            <Dropdown :items="integrationOptions" :full-width="true">
              <template #trigger>
                <div class="ws-create-content-step-item-dropdown-trigger">
                  <img
                    :src="selectedIntegration.icon"
                    class="ws-create-content-step-item-dropdown-trigger-icon"
                  />
                  {{ selectedIntegration.text }}
                  <Icon
                    name="chevron-down-medium"
                    class="ws-create-content-step-item-dropdown-trigger-chevron"
                  />
                </div>
              </template>
              <template #item="{ item }">
                <div class="ws-create-content-step-item-dropdown-item">
                  <img
                    :src="item.icon"
                    class="ws-create-content-step-item-dropdown-item-icon"
                  />
                  {{ item.text }}
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              Workspace language
              <span class="required">*</span>
            </p>
            <LanguageSelect v-model="language" />
          </div>
          <div class="ws-create-content-step-disclaimer">
            Fields marked with <span class="required">*</span> are required
          </div>
        </div>
        <div v-if="currentStep === 1" class="ws-create-content-step">
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              URLs related to your workspace
              <span class="optional">(optional)</span>
              <Button
                type="grey"
                text="Add URL"
                icon="plus-medium"
                size="xs"
                class="ws-create-content-step-item-header-button"
                @click="addEmptyUrl"
              />
            </p>
            <div
              v-for="(url, idx) in urls"
              :key="`ws-create-url-${idx}`"
              class="ws-create-content-step-item-textinput"
            >
              <URLInput
                :model-value="url"
                class="ws-create-content-step-item-textinput-input"
                placeholder="Add a URL"
                @update:modelValue="(val) => handleUrlInput(val, idx)"
              />
              <Button
                v-if="idx"
                type="grey"
                icon="bin"
                size="xs"
                @click="() => removeUrl(idx)"
              />
            </div>
          </div>
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              Files related to your workspace
              <span class="optional">(optional)</span>
            </p>
            <UploadDropzone
              :show-drive="false"
              :props-call="fileUploadPropsCall"
              @upload="handleUpload"
              @remove="removeFile"
            />
          </div>
        </div>
        <div v-if="currentStep === 2" class="ws-create-content-step">
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">Workspace Colors</p>
            <div class="ws-create-content-step-item-subtitle">
              <p>
                These colors will be used to generate the icons for your
                portfolio.
              </p>
              <p>The secondary color is optional and can be toggled on.</p>
            </div>
            <div class="ws-create-content-step-colors" @click.stop>
              <div
                v-for="color in colors"
                :key="`ws-create-color-${color.key}`"
                class="ws-create-content-step-color"
                :class="{
                  disabled: color.key === 'secondary' && !useSecondary
                }"
              >
                <div class="ws-create-content-step-color-header">
                  {{ color.name }}
                  <SwitchToggle
                    v-if="color.key === 'secondary'"
                    :toggled="useSecondary"
                    @update:toggled="(us) => (useSecondary = us)"
                  />
                </div>
                <ColorPicker
                  v-model="color.value"
                  size="s"
                  class="ws-create-content-step-color-input"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentStep === 3" class="ws-create-content-step">
          <div class="ws-create-content-step-item">
            <p class="ws-create-content-step-item-header">
              Invite team members
              <span class="optional">(optional)</span>
              <Button
                type="grey"
                text="Add email"
                icon="plus-medium"
                size="xs"
                class="ws-create-content-step-item-header-button"
                @click="addEmptyInvite"
              />
            </p>
            <div class="ws-create-content-step-item-subtitle">
              <p>Invite some team members to help set up your workspace</p>
              <p>
                You can always invite more people from the settings once your
                workspace has been completed
              </p>
            </div>
            <div
              v-for="(invite, idx) in invites"
              :key="`ws-create-invite-${idx}`"
              class="ws-create-content-step-item-textinput"
            >
              <TextInput
                :model-value="invite"
                class="ws-create-content-step-item-textinput-input"
                placeholder="Enter an email address"
                @update:modelValue="(val) => handleInviteInput(val, idx)"
              />
              <Button
                v-if="idx"
                type="grey"
                icon="bin"
                size="xs"
                @click="() => removeInvite(idx)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import OrganisationSearch from '@/components/organisations/OrganisationSearch.vue'
import { filestorageIntegrations } from '@/core/integrations'
import { getUserFileUploadProps } from '@/services/user'
import { computed, inject, ref } from 'vue'
import { createWorkspace } from '@/services/workspace'
import { useRouter } from 'vue-router'
import { validateURL } from '@/core/url'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const steps = ref([
  {
    key: 'name',
    title: 'Organisation & name'
  },
  {
    key: 'draft',
    title: 'URLs & files'
  },
  {
    key: 'colors',
    title: 'Colors'
  },
  {
    key: 'invites',
    title: 'Invites'
  }
])

const emit = defineEmits(['close'])
const toast = inject('$toast')
const console = inject('$console')
const queue = inject('$queue')
const router = useRouter()

const currentStep = ref(0)

function next() {
  if (currentStep.value < steps.value.length - 1) {
    currentStep.value++
  } else {
    submit()
  }
}

function submit() {
  queue.addTask({
    loading: `Creating workspace ${name.value}`,
    error: `Couldn't create workspace ${name.value}`,
    done: () => `Workspace ${name.value} created`,
    cta: () => 'View workspace',
    callback: (res) => {
      router.push({ name: 'home', params: { workspace_id: res.uuid } })
    },
    run: async () => {
      const u = urls.value.filter(Boolean).filter(validateURL)
      const f = Object.values(uploadStates.value)
      const draft =
        u.length || f.length
          ? {
              ...(u.length ? { urls: u } : {}),
              ...(f.length ? { files: f } : {})
            }
          : undefined

      const c = {
        background: colors.value.find((c) => c.key === 'background').value,
        primary: colors.value.find((c) => c.key === 'primary').value,
        ...(useSecondary.value
          ? { secondary: colors.value.find((c) => c.key === 'secondary').value }
          : {})
      }
      const emails = invites.value.filter(Boolean)
      return createWorkspace({
        name: name.value,
        colors: c,
        integration_type: integration.value,
        language: language.value,
        ...(organisation.value?.linkedin_url
          ? { linkedin_id: organisation.value.linkedin_url }
          : {}),
        ...(draft ? { draft } : {}),
        ...(emails.length ? { emails } : {})
      })
    }
  })
  close()
}

function prev() {
  if (currentStep.value > 0) {
    currentStep.value--
  } else {
    close()
  }
}

function close() {
  emit('close')
}

const organisation = ref(null)
const name = ref('')
const integration = ref('google_drive')
const language = ref('en')
const urls = ref([''])
const files = ref([])
const colors = ref([
  {
    key: 'background',
    name: 'Background',
    value: '#ffffff'
  },
  {
    key: 'primary',
    name: 'Primary',
    value: '#000000'
  },
  {
    key: 'secondary',
    name: 'Secondary',
    value: '#808080'
  }
])
const useSecondary = ref(false)
const invites = ref([''])

const disableNext = computed(() => !name.value)

function selectStep(idx) {
  if (!disableNext.value) {
    currentStep.value = idx
  } else {
    toast.danger('Please fill in the required fields first')
  }
}

const integrations = Object.values(filestorageIntegrations)
const integrationOptions = integrations.map((i) => ({
  key: i.key,
  text: i.title,
  icon: i.image,
  callback: () => (integration.value = i.key)
}))

const selectedIntegration = computed(() =>
  integrationOptions.find((option) => option.key === integration.value)
)

function selectOrganisation(org) {
  organisation.value = org
  if (!name.value) name.value = org.name
  if (!!org.domain && urls.value.length === 1 && !urls.value[0]) {
    let domain = org.domain
    domain = domain.startsWith('http') ? domain : `https://${domain}`
    urls.value.unshift(domain)
  }
}

function removeOrganisation() {
  if (name.value === organisation.value.name) name.value = ''
  if (
    urls.value.length === 2 &&
    urls.value[0].includes(organisation.value.domain)
  )
    urls.value = ['']
  organisation.value = null
}

function handleUrlInput(val, idx) {
  urls.value[idx] = val
  if (idx === urls.value.length - 1 && val) urls.value.push('')
}

function removeUrl(idx) {
  urls.value.splice(idx, 1)
}

function addEmptyUrl() {
  urls.value.push('')
}

async function fileUploadPropsCall(file) {
  return getUserFileUploadProps({
    name: file.name,
    mimetype: file.type,
    content_length: file.size
  })
}

const uploadStates = ref({})
const uploadFinished = ref([])

function handleUpload({ file, progress, props, error }) {
  if (!files.value.find((f) => f.name === file.name)) files.value.push(file)
  if (props?.state) uploadStates.value[file.name] = props.state
  if (progress === 100) uploadFinished.value.push(file.name)
  if (error) {
    toast.error(error, `uploading file ${file.name}`)
    console.debug('Error uploading file', error)
    removeFile(file)
  }
}

function removeFile(file) {
  const index = files.value.findIndex((f) => f.name === file.name)
  if (index > -1) files.value.splice(index, 1)
  uploadFinished.value = uploadFinished.value.filter((f) => f !== file.name)
  if (uploadStates.value[file.name]) delete uploadStates.value[file.name]
}

function handleInviteInput(val, idx) {
  invites.value[idx] = val
  if (idx === invites.value.length - 1 && val) invites.value.push('')
}

function removeInvite(idx) {
  invites.value.splice(idx, 1)
}

function addEmptyInvite() {
  invites.value.push('')
}
</script>

<style lang="scss" scoped>
.ws-create {
  display: flex;
  flex-flow: row nowrap;

  &-sidebar {
    display: flex;
    flex-flow: column nowrap;
    padding: 1.5rem 0;
    background: $grey-100;
    border-right: 1px solid $border-color;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      position: relative;
      border-radius: 4px;
      padding: 0.75rem 1.75rem;

      &.selected {
        background: $primary-light;
        color: $primary;

        & .ws-create-sidebar-item-count {
          background: $primary;
          color: white;
        }
      }

      &.done {
        background: $grey-200;
        color: $grey-500;

        & .ws-create-sidebar-item-count {
          background: $grey-500;
        }
      }

      &:not(.selected) {
        &:hover {
          background: $grey-200;
        }
      }

      &-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 999rem;
        background: $grey-300;
        color: $grey-500;
        font-weight: 600;
        font-size: 1rem;

        &-content {
          height: 1.2rem;
          width: 1.2rem;
          text-align: center;
        }
      }

      &-title {
        font-weight: 600;
      }
    }
  }

  &-content {
    flex: 1;
    padding: 1.5rem;

    &-step {
      display: flex;
      flex-flow: column nowrap;
      gap: 1.5rem;
      min-height: 25rem;

      &-item {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;

        &-header {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;
          font-weight: 600;

          &-button {
            margin-left: auto;
          }
        }

        &-subtitle {
          color: $grey;
        }

        &-dropdown {
          &-trigger {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 0.5rem 0.75rem;
            border-radius: 4px;
            border: 1px solid $border-color;
            cursor: pointer;
            background: $white;

            &:hover {
              border: 1px solid rgba($black, 0.16);
            }

            &-icon {
              height: 1.2rem;
            }

            &-chevron {
              height: 1.2rem;
              margin-left: auto;
            }
          }

          &-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 0.5rem;

            &-icon {
              height: 1.2rem;
            }
          }
        }

        &-textinput {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;

          &-input {
            flex: 1;
          }
        }
      }

      &-disclaimer {
        border-radius: 4px;
        color: $grey;
        text-align: end;
      }

      &-colors {
        display: flex;
        flex-flow: row nowrap;
        gap: 1rem;
        margin-top: 0.5rem;
      }

      &-color {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        background: $grey-200;
        padding: 1rem 1.5rem;
        border-radius: 6px;

        &.disabled {
          opacity: 0.5;

          & .ws-create-content-step-color-input {
            pointer-events: none;
          }
        }

        &-header {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          gap: 0.5rem;
          font-weight: 700;
          color: $grey;
        }

        &-input {
          align-self: center;
        }
      }

      & .optional {
        color: $grey-500;
        font-weight: 400;
      }

      & .required {
        color: $red;
        font-weight: 600;
      }
    }
  }
}
</style>
