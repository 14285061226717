<template>
  <div class="url-input-wrapper">
    <p v-if="title" class="url-input-title">{{ title }}</p>
    <Tooltip
      :active="invalid"
      text="Make sure your URL works in your browser. It might contain some invalid characters."
      :append-to-body="true"
      :full-width="true"
    >
      <div class="url-input">
        <div class="url-input-prefix" :class="{ [size]: true }">
          {{ prefix }}
        </div>
        <input
          id=""
          ref="urlinput"
          type="text"
          name=""
          :value="visibleValue"
          :placeholder="placeholder"
          :disabled="disabled || loading"
          :style="{ background: mapColor(background) }"
          class="url-input-input"
          :class="{
            disabled: disabled || loading,
            focused,
            invalid,
            valid,
            [size]: true
          }"
          @keydown.enter="handleSubmit"
          @input="handleInput"
          @focus="(e) => $emit('focus', e)"
          @blur="(e) => $emit('blur', e)"
          @copy="handleCopy"
        />
      </div>
    </Tooltip>
  </div>
</template>

<script>
import { mapColor } from '@/core/colors'
import { validateURL } from '@/core/url'

export default {
  name: 'URLInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: (value) => ['s', 'm', 'l'].includes(value)
    },
    title: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: 'https://'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'grey-100'
    }
  },
  data: () => ({
    inputPaddingLeft: 0,
    inputPaddingRight: 0
  }),
  emits: ['update:modelValue', 'focus', 'blur', 'submit'],
  computed: {
    visibleValue() {
      return this.modelValue.startsWith(this.prefix)
        ? this.modelValue.slice(this.prefix.length)
        : this.modelValue
    },
    rem() {
      return parseFloat(getComputedStyle(document.documentElement).fontSize)
    },
    valid() {
      return validateURL(this.modelValue) === 1
    },
    invalid() {
      return validateURL(this.modelValue) === 0
    }
  },
  methods: {
    mapColor,
    focus() {
      this.$refs.textinput.focus()
    },
    blur() {
      this.$refs.textinput.blur()
    },
    handleSubmit() {
      this.$emit('submit', this.modelValue)
    },
    handleInput(e) {
      let val = e.target.value
      if (val.startsWith(this.prefix)) val = val.slice(this.prefix.length)
      this.$emit('update:modelValue', val ? `${this.prefix}${val}` : val)
    },
    handleCopy(e) {
      const selection = document.getSelection()
      if (selection.toString() === this.visibleValue) {
        e.preventDefault()
        e.clipboardData.setData('text/plain', this.modelValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.url-input {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid $grey-400;
  outline: none;
  border-radius: 0.5rem;

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }

  &-title {
    font-weight: 600;
    color: $dark;
  }

  &-prefix {
    padding: 0.5rem 1rem;
    background: $grey-200;
    border-right: 1px solid $grey-400;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    color: $grey-500;

    &.s {
      padding: 0.5rem 1rem;
    }

    &.m {
      padding: 0.75rem 1rem;
    }

    &.l {
      padding: 1rem 1.5rem;
    }
  }

  &-input {
    width: 100%;
    border: none;
    outline: none;
    border: 1px solid transparent;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &.disabled {
      background: $grey-100;
      border: 1px solid $grey-100;
      color: $grey-400;
      cursor: not-allowed;
    }

    &.valid {
      border-color: $green !important;
    }

    &.invalid {
      border-color: $red !important;
    }

    &.s {
      padding: 0.5rem 0.85rem;
    }

    &.m {
      padding: 0.75rem 0.85rem;
    }

    &.l {
      padding: 1rem;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid $primary;
      background: $white !important;
    }

    &::placeholder {
      color: $grey-500;
    }
  }
}
</style>
