import { getClient, getResponseData, Paginator } from '@/core/axios'
import { useFeedbackStore } from '@/stores/feedback'
import { searchDetail, searchSourcePage, searchTrigger } from '@/core/feedback'
import { SearchFacet, SearchFacetMap } from '@/core/resources'

const client = getClient('/workspaces')

// RESOURCES

export async function getResources({ workspace_id, act_as, highlighted }) {
  const res = await client.get(`/${workspace_id}/resources/`, {
    params: {
      ...(highlighted ? { highlighted } : {}),
      ...(act_as ? { act_as } : {})
    }
  })
  return getResponseData(res)
}

export async function getBulkResources({ workspace_id, act_as, resource_ids }) {
  const res = await client.get(`/${workspace_id}/resources/get-bulk/`, {
    params: {
      resource_ids,
      ...(act_as ? { act_as } : {})
    }
  })
  return getResponseData(res)
}

export async function getResource({ workspace_id, resource_id, act_as }) {
  const res = await client.get(`/${workspace_id}/resources/${resource_id}/`, {
    params: {
      ...(act_as ? { act_as } : {})
    }
  })
  return getResponseData(res)
}

export async function searchResources({
  workspace_id,
  resource_level, // resources or subresources
  data, // search body
  // should always include
  //   query, sort_by, tab
  // can also include
  //   extract_entities, relevancy, content_classes, industries,
  //   organisations, topics, created_dates, modified_dates, languages,
  //   file_locations, mimetypes, labels, bookmarks, resolutions,
  //   authors, editors, types and with_search_highlights
  params, // search params
  // should always include page_size and included_facets
  // can also include act_as
  paginated = true
}) {
  if (!resource_level) return

  let body = {
    ...data
  }

  const feedbackStore = useFeedbackStore()
  const searchEnrichmentContext = feedbackStore.searchEnrichmentContext
  const searchEnrichment = searchEnrichmentContext?.trigger
    ? searchEnrichmentContext
    : {
        page: searchSourcePage.search_results,
        trigger: searchTrigger.search,
        detail: searchDetail.search.url
      }

  let facetKey =
    {
      subresources: 'subresources',
      resources: 'resources'
    }[resource_level]
  if ((body.types || []).includes('video')) facetKey = 'video'
  const included_facets =
    params.included_facets ||
    [
      ...SearchFacetMap[facetKey],
      ...(body.tab === 'all' && resource_level === 'resources'
        ? [SearchFacet.insights]
        : [])
    ].join(' ')

  const response = await client.post(
    `/${workspace_id}/${resource_level}/search/`,
    {
      ...body,
      ...searchEnrichment
    },
    {
      params: {
        ...params,
        included_facets
      },
      timeout: 10000
    }
  )

  if (!(body.tab === 'all' && resource_level === 'resources'))
    feedbackStore.setSearchEnrichmentContext(undefined)
  if (!searchEnrichmentContext?.trigger)
    feedbackStore.setSearchEnrichmentContext({
      trigger: 'search',
      detail: 'url'
    })

  return paginated
    ? new Paginator(
        client,
        response,
        () => {},
        resource_level,
        async (url) => {
          const searchEnrichment = feedbackStore.searchEnrichmentContext
          return client.post(
            url,
            {
              ...body,
              ...searchEnrichment
            },
            { timeout: 10000 }
          )
        }
      )
    : response
}

export async function getResourceDownloadLink({
  workspace_id,
  resource_id,
  act_as
}) {
  const res = await client.get(
    `/${workspace_id}/resources/${resource_id}/content/`,
    {
      params: {
        ...(act_as ? { act_as } : {})
      }
    }
  )
  return getResponseData(res)
}

// SUBRESOURCES

export async function getSubresource({ workspace_id, subresource_id, act_as }) {
  const res = await client.get(
    `/${workspace_id}/subresources/${subresource_id}/`,
    {
      params: {
        ...(act_as ? { act_as } : {})
      }
    }
  )
  return getResponseData(res)
}

export async function getSubResourcePaginator({
  workspace_id,
  resource_id,
  params
}) {
  return new Paginator(
    client,
    client.get(`/${workspace_id}/resources/${resource_id}/subresources/`, {
      params
    }),
    () => {}
  )
}

export async function getSubResourcePage({
  workspace_id,
  resource_id,
  params
}) {
  const res = await client.get(
    `/${workspace_id}/resources/${resource_id}/subresources/`,
    { params }
  )
  return getResponseData(res)
}

export async function getSimilarSlidesPaginator({
  workspace_id,
  slide_id,
  params,
  act_as,
  callback
}) {
  return new Paginator(
    client,
    client.get(`/${workspace_id}/subresources/${slide_id}/similar/`, {
      params: {
        ...(params || {}),
        ...(act_as ? { act_as } : {})
      }
    }),
    callback,
    'results'
  )
}

// SUMMARIZE

export async function getResourceSummarization({
  workspace_id,
  resource_id,
  act_as
}) {
  const res = await client.get(
    `/${workspace_id}/resources/${resource_id}/summarize/`,
    { ...(act_as ? { params: { act_as } } : {}) }
  )
  return getResponseData(res)
}

// HIGHLIGHTS

export async function getHighlightedResources({ workspace_id, act_as }) {
  const res = await client.get(`/${workspace_id}/resources/`, {
    params: {
      highlighted: true,
      ...(act_as ? { act_as } : {})
    }
  })
  return getResponseData(res)
}

export async function highlightResource({ workspace_id, resource_id, act_as }) {
  const res = await client.post(
    `/${workspace_id}/resources/${resource_id}/highlight/`,
    {},
    {
      ...(act_as ? { params: { act_as } } : {})
    }
  )
  return getResponseData(res)
}

export async function removeHighlightFromResource({
  workspace_id,
  resource_id,
  act_as
}) {
  await client.delete(`/${workspace_id}/resources/${resource_id}/highlight/`, {
    ...(act_as ? { params: { act_as } } : {})
  })
}

export async function pinHighlightedResource({
  workspace_id,
  resource_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/resources/${resource_id}/highlight/pin/`,
    {},
    {
      ...(act_as ? { params: { act_as } } : {})
    }
  )
  return getResponseData(res)
}

export async function removePinFromHighlight({
  workspace_id,
  resource_id,
  act_as
}) {
  await client.delete(
    `/${workspace_id}/resources/${resource_id}/highlight/pin/`,
    {
      ...(act_as ? { params: { act_as } } : {})
    }
  )
}

// BOOKMARKS

export async function addBookmarkToResource({
  workspace_id,
  resource_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/resources/${resource_id}/bookmark/`,
    {},
    {
      ...(act_as && { params: { act_as } })
    }
  )
  return getResponseData(res)
}

export async function deleteBookmarkFromResource({
  workspace_id,
  resource_id,
  act_as
}) {
  await client.delete(`/${workspace_id}/resources/${resource_id}/bookmark/`, {
    ...(act_as ? { params: { act_as } } : {})
  })
}

export async function addBookmarkToSubresource({
  workspace_id,
  subresource_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/subresources/${subresource_id}/bookmark/`,
    {},
    {
      ...(act_as && { params: { act_as } })
    }
  )
  return getResponseData(res)
}

export async function deleteBookmarkFromSubresource({
  workspace_id,
  subresource_id,
  act_as
}) {
  await client.delete(
    `/${workspace_id}/subresources/${subresource_id}/bookmark/`,
    {
      ...(act_as ? { params: { act_as } } : {})
    }
  )
}

// SEARCH HISTORY

export async function getSearchSuggestions({
  workspace_id,
  member_id,
  search,
  tab
}) {
  const res = await client.get(
    `/${workspace_id}/members/${member_id}/suggestions/`,
    {
      params: {
        search,
        tab
      }
    }
  )
  return getResponseData(res)
}

export async function deleteSearchSuggestion({
  workspace_id,
  member_id,
  history_item_id
}) {
  await client.delete(
    `/${workspace_id}/members/${member_id}/suggestions/${history_item_id}/`
  )
}
