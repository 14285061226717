<template>
  <div class="org-item" :class="{ bordered }">
    <div class="org-item-avatar">
      <Avatar
        :user="{ avatar: loading ? '' : item.icon || item.logo }"
        fallback-icon="company"
        size="m"
      />
      <Loader
        v-if="loading"
        size="s"
        :overlay="true"
        class="org-item-avatar-loading"
      />
    </div>
    <div class="org-item-info" :class="{ loading }">
      <div class="org-item-name">
        <Skeleton v-if="loading" height="1.2rem" width="min(100%, 15rem)" />
        <span v-else>{{ item.name || item.linkedin_url }}</span>
        <Icon
          v-if="!loading && item.accountId"
          name="check-circle"
          class="org-item-check"
          fill="primary"
        />
        <a
          v-if="!loading && item.linkedin_url"
          :href="validUrl(item.linkedin_url)"
          @click.stop
          target="_blank"
        >
          <Icon
            name="linkedin"
            path="/logos/"
            class="org-item-linkedin"
            :set-fill="false"
          />
        </a>
      </div>
      <div
        v-if="
          !loading &&
          (item.followers ||
            (item.industry && item.industry.name) ||
            item.headquarters)
        "
        class="org-item-props"
      >
        <div v-if="item.followers" class="org-item-props-item">
          <Icon name="users" class="org-item-props-item-icon" fill="grey" />
          <p class="org-item-props-item-text">{{ item.followers }} followers</p>
        </div>
        <div
          v-if="item.industry && item.industry.name"
          class="org-item-props-item"
        >
          <p v-if="item.followers" class="org-item-props-dot">&bull;</p>
          <Icon name="industry" class="org-item-props-item-icon" fill="grey" />
          <p class="org-item-props-item-text">{{ item.industry.name }}</p>
        </div>
        <div v-if="item.headquarters" class="org-item-props-item">
          <p
            v-if="(item.industry && item.industry.name) || item.followers"
            class="org-item-props-dot"
          >
            &bull;
          </p>
          <Icon name="location" class="org-item-props-item-icon" fill="grey" />
          <p class="org-item-props-item-text">{{ item.headquarters }}</p>
        </div>
      </div>
      <Skeleton v-if="loading" height="1.2rem" width="min(70%, 11.5rem)" />
      <a
        v-else-if="item.domain"
        :href="validUrl(item.domain)"
        target="_blank"
        class="org-item-domain-wrapper"
        @click.stop
      >
        <div class="org-item-domain">
          <Icon name="globe" class="org-item-domain-icon" fill="primary" />
          {{ item.domain }}
        </div>
      </a>
    </div>
    <Button
      v-if="!loading && removable"
      icon="close"
      type="white"
      size="xxs"
      @click="() => $emit('remove', item)"
    />
  </div>
</template>

<script>
export default {
  name: 'OrganisationSearchItem',
  emits: ['remove'],
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    removable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validUrl(url) {
      return url.startsWith('http') ? url : `https://${url}`
    }
  }
}
</script>

<style lang="scss" scoped>
.org-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;

  &.bordered {
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid $border-color;
  }

  &-avatar {
    position: relative;

    &-loading {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 999rem;
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;

    &.loading {
      gap: 2px;
    }
  }

  &-name {
    font-weight: 600;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
  }

  &-check {
    height: 1rem;
  }

  &-linkedin {
    height: 1.1rem;
    cursor: pointer;
  }

  &-props {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    color: $grey;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.25rem;
      align-items: center;

      &-icon {
        height: 1rem;
      }
    }

    &-dot {
      color: $grey-500;
      padding-right: 0.25rem;
    }
  }

  &-domain {
    color: $primary;
    cursor: pointer;
    width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.25rem;
    align-items: center;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.25rem;
      align-items: center;
      width: fit-content;
    }

    &-icon {
      height: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
