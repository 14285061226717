import { useViewAuth } from '@/core/routes'
import { setCurrentWorkspace } from '@/core/workspace'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import { storeToRefs } from 'pinia'

export function addRouterGuards(router) {
  router.beforeEach(async (to, from, next) => {
    let route = {}

    // Keep act_as query param when navigating between pages
    if (!!from.query.act_as && !to.query.act_as) {
      if (to.query.act_as === '') {
        const q = { ...to.query }
        delete q.act_as
        route = {
          name: to.name,
          params: to.params,
          query: q
        }
        from.query.act_as = ''
      } else {
        route = {
          name: to.name,
          params: to.params,
          query: {
            ...to.query,
            act_as: from.query.act_as
          }
        }
      }
    }

    if (Object.keys(route).length) return next(route)

    // USER LEVEL AUTHENTICATION
    const auth = useAuthStore()
    let routeAuthentication = true
    if ('authentication' in to.meta)
      routeAuthentication = to.meta.authentication
    else to.meta.authentication = routeAuthentication

    route = {
      ...(routeAuthentication !== auth.authenticated ||
      (auth.authenticated && to.name === 'root')
        ? { name: auth.authenticated ? 'home' : 'login' }
        : {})
    }

    // Early return to prevent any further checks that require authentication, if user is not authenticated
    if (route.name === 'login') return next(route)
    if (!Object.keys(route).length && !to.meta.authentication) return next()

    // CHECK IF USER NEEDS ONBOARDING
    const user = useUserStore()
    const { onboardingRequired } = storeToRefs(user)
    if (
      (!Object.keys(route).length || route.name === 'home') &&
      onboardingRequired.value &&
      to.name !== 'onboarding'
    ) {
      route = {
        name: 'onboarding'
      }
    }

    // WORKSPACE ID IN ROUTE
    const workspace = useWorkspaceStore()

    if (!workspace.workspaces.length) {
      auth.logout()
      route = { name: 'login', query: { error: 'noworkspaces' } }
      return next(route)
    }

    let workspace_id = route?.name
      ? route.params?.workspace_id
      : (workspace.workspaces || []).some(
            (ws) => ws.uuid === to.params.workspace_id
          )
        ? to.params.workspace_id
        : ''
    const workspaceIdRequired = route?.name
      ? ['onboarding', 'home'].includes(route.name)
      : to.meta.authentication && to.name !== 'integrationOauth'
    if (!workspace_id && workspaceIdRequired) {
      const storedWorkspace = localStorage.getItem('uman_last_workspace')
      workspace_id =
        !!storedWorkspace &&
        workspace.workspaces.some((ws) => ws.uuid === storedWorkspace)
          ? storedWorkspace
          : workspace.workspaces[0]?.uuid

      route = {
        ...route,
        params: {
          workspace_id
        }
      }
    }

    // Early return to make sure route isn't overwritten in next step
    if (Object.keys(route).length) return next(route)

    // WORKSPACE LEVEL PERMISSION AUTH
    const newId = route?.params?.workspace_id || to.params.workspace_id
    if (newId !== workspace.currentWorkspace?.uuid) {
      await setCurrentWorkspace(newId)
    }

    if (!!newId) {
      const { authenticatedRoutesWithChildren } = useViewAuth()
      if (
        Object.keys(authenticatedRoutesWithChildren.value).includes(to.name) &&
        !authenticatedRoutesWithChildren.value[to.name]
      ) {
        route = {
          name: 'home',
          params: { workspace_id: newId }
        }
      }
    }

    if (Object.keys(route).length) return next(route)
    else next()
  })
}
