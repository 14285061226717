import Accordion from '@l/Accordion.vue'
import Animation from '@l/Animation.vue'
import Avatar from '@l/Avatar.vue'
import AvatarUpload from '@l/AvatarUpload.vue'
import Button from '@l/Button.vue'
import Chart from '@l/Chart.vue'
import Checkbox from '@l/Checkbox.vue'
import ColorPicker from '@l/ColorPicker.vue'
import DocumentTag from '@l/DocumentTag.vue'
import DraggableAccordion from '@l/DraggableAccordion.vue'
import DraggableCarousel from '@l/DraggableCarousel.vue'
import DraggableGrid from '@l/DraggableGrid.vue'
import DraggableList from '@l/DraggableList.vue'
import Dropdown from '@l/Dropdown.vue'
import FilterDropdown from '@l/FilterDropdown.vue'
import GenerateAvatar from '@l/GenerateAvatar.vue'
import HTMLRenderer from '@l/HTMLRenderer.vue'
import Icon from '@l/Icon.vue'
import ImportModal from '@l/ImportModal.vue'
import InlineSearch from '@l/InlineSearch.vue'
import LanguageSelect from '@l/LanguageSelect.vue'
import Loader from '@l/Loader.vue'
import MarkdownEdit from '@l/MarkdownEdit.vue'
import Modal from '@l/Modal.vue'
import NumberInput from '@l/NumberInput.vue'
import ProgressBar from '@l/ProgressBar.vue'
import ProgressCircle from '@l/ProgressCircle.vue'
import Score from '@l/Score.vue'
import SegmentedButton from '@l/SegmentedButton.vue'
import Skeleton from '@l/Skeleton.vue'
import SlideDeck from '@l/SlideDeck.vue'
import Snackbar from '@l/Snackbar.vue'
import StatusOrb from '@l/StatusOrb.vue'
import SwitchToggle from '@l/SwitchToggle.vue'
import Table from '@l/Table.vue'
import TableColumnSelect from '@l/TableColumnSelect.vue'
import Tabs from '@l/Tabs.vue'
import Tag from '@l/Tag.vue'
import TextArea from '@l/TextArea.vue'
import TextInput from '@l/TextInput.vue'
import Toast from '@l/Toast.vue'
import Tooltip from '@l/Tooltip.vue'
import UploadDownload from '@l/UploadDownload.vue'
import UploadDownloadDropdown from '@l/UploadDownloadDropdown.vue'
import UploadDropzone from '@l/UploadDropzone.vue'
import URLInput from '@l/URLInput.vue'
import ViewSwitch from '@l/ViewSwitch.vue'

export function useLibraryComponents(app) {
  app.component('Accordion', Accordion)
  app.component('Animation', Animation)
  app.component('Avatar', Avatar)
  app.component('AvatarUpload', AvatarUpload)
  app.component('Button', Button)
  app.component('Chart', Chart)
  app.component('Checkbox', Checkbox)
  app.component('ColorPicker', ColorPicker)
  app.component('DocumentTag', DocumentTag)
  app.component('DraggableAccordion', DraggableAccordion)
  app.component('DraggableCarousel', DraggableCarousel)
  app.component('DraggableGrid', DraggableGrid)
  app.component('DraggableList', DraggableList)
  app.component('Dropdown', Dropdown)
  app.component('FilterDropdown', FilterDropdown)
  app.component('GenerateAvatar', GenerateAvatar)
  app.component('HTMLRenderer', HTMLRenderer)
  app.component('Icon', Icon)
  app.component('ImportModal', ImportModal)
  app.component('InlineSearch', InlineSearch)
  app.component('LanguageSelect', LanguageSelect)
  app.component('Loader', Loader)
  app.component('MarkdownEdit', MarkdownEdit)
  app.component('Modal', Modal)
  app.component('NumberInput', NumberInput)
  app.component('ProgressBar', ProgressBar)
  app.component('ProgressCircle', ProgressCircle)
  app.component('Score', Score)
  app.component('SegmentedButton', SegmentedButton)
  app.component('Skeleton', Skeleton)
  app.component('SlideDeck', SlideDeck)
  app.component('Snackbar', Snackbar)
  app.component('StatusOrb', StatusOrb)
  app.component('SwitchToggle', SwitchToggle)
  app.component('Table', Table)
  app.component('TableColumnSelect', TableColumnSelect)
  app.component('Tabs', Tabs)
  app.component('Tag', Tag)
  app.component('TextArea', TextArea)
  app.component('TextInput', TextInput)
  app.component('Toast', Toast)
  app.component('Tooltip', Tooltip)
  app.component('UploadDownload', UploadDownload)
  app.component('UploadDownloadDropdown', UploadDownloadDropdown)
  app.component('UploadDropzone', UploadDropzone)
  app.component('URLInput', URLInput)
  app.component('ViewSwitch', ViewSwitch)
}
