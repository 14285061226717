<template>
  <button
    class="ubutton"
    :class="{
      [type]: true,
      [size]: true,
      disabled: disabled || loading,
      'full-width': fullWidth,
      'icon-only': !text
    }"
    :style="{
      ...(background ? { background: `${backgroundColor} !important` } : {})
    }"
    @click="$emit('click')"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <Icon
      v-if="icon && iconLeft"
      :name="icon"
      :fill="iconFill"
      :set-fill="setFill"
      class="ubutton-icon"
    />
    <slot name="left"></slot>
    <span v-if="text" class="ubutton-text" :style="textStyle">{{ text }}</span>
    <slot name="right"></slot>
    <Icon
      v-if="icon && !iconLeft"
      :name="icon"
      :fill="iconFill"
      :set-fill="setFill"
      class="ubutton-icon"
    />
    <div v-if="disabled || loading" class="ubutton-overlay">
      <Loader v-if="loading" size="xs" color="dark" />
    </div>
  </button>
</template>

<script>
import { mapColor } from '@/core/colors'

export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: (s) =>
        [
          'primary',
          'light',
          'light-solid',
          'white',
          'white-outline',
          'grey',
          'black',
          'red'
        ].includes(s)
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => ['xxs', 'xs', 's', 'm', 'l'].includes(s)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: ''
    },
    setFill: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    filterIcon: {
      type: Boolean,
      default: true
    },
    background: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    fillColor() {
      if (!this.fill) return ''
      return mapColor(this.fill)
    },
    iconFill() {
      if (!this.icon) return ''
      if (!this.filterIcon) return 'dark'
      if (this.fill) return this.fillColor
      return {
        primary: 'white',
        light: 'primary',
        'light-solid': 'primary',
        white: this.hovered ? 'dark' : 'grey',
        'white-outline': 'white',
        grey: this.hovered ? 'dark' : 'grey',
        black: 'white',
        red: 'red'
      }[this.type]
    },
    backgroundColor() {
      return mapColor(this.background)
    },
    textStyle() {
      return {
        ...(this.fill ? { color: this.fillColor } : {})
      }
    }
  },
  methods: {
    click() {
      if (!this.disabled && !this.loading) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ubutton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  border-radius: 999rem;
  cursor: pointer;
  outline: inherit;
  transition: background 0.2s ease;
  position: relative;
  height: fit-content;
  width: fit-content;

  &-icon {
    transition: all 0.2s ease;
  }

  &-text {
    transition: all 0.2s ease;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
  }

  &.primary {
    background: $primary;
    color: $white;
    border: 1px solid transparent;
    & > .ubutton-text {
      color: $white;
    }

    &:hover {
      background: hover($primary);
    }
  }

  &.light {
    background: rgba($primary-light, 0%);
    border: 1px solid transparent;

    & > .ubutton-text {
      color: $primary;
    }

    &:hover {
      background: $primary-light;
    }
  }
  &.light-solid {
    background: $primary-light;
    border: 1px solid $primary;

    & > .ubutton-text {
      color: $primary;
    }

    &:hover {
      background: hover($primary-light);
    }
  }

  &.white {
    background: $white;
    border: 1px solid $grey-400;

    & > .ubutton-text {
      color: $grey;
    }

    &:hover {
      background: hover($white);
      border: 1px solid $transparent;
      & > .ubutton-text {
        color: $dark;
      }
    }
  }

  &.white-outline {
    background: transparent;
    border: 1px solid $white;

    & > .ubutton-text {
      color: $white;
    }

    &:hover {
      background: rgba($black, 6%);
    }
  }

  &.grey {
    background: transparent;
    border: 1px solid transparent;

    & > .ubutton-text {
      color: $grey;
    }

    &:hover {
      background: rgba($black, 6%);
      & > .ubutton-text {
        color: $dark;
      }
    }
  }

  &.black {
    background: rgba($black, 64%);
    border: 1px solid transparent;

    & > .ubutton-text {
      color: $white;
    }

    &:hover {
      background: black;
    }
  }

  &.red {
    background: $red-light;
    border: 1px solid $red;

    & > .ubutton-text {
      color: $red;
    }

    &:hover {
      background: hover($red-light);
    }
  }

  &.icon-only {
    justify-content: center;
    padding: unset !important;

    &.xxs {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }

    &.xs {
      width: 1.85rem;
      height: 1.85rem;
      min-width: 1.85rem;
      min-height: 1.85rem;
    }

    &.s {
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
    }

    &.m {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
    }

    &.l {
      width: 3.45rem;
      height: 3.45rem;
      min-width: 3.45rem;
      min-height: 3.45rem;
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &-overlay {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 999rem;
    background: rgba($white, 0.5);
  }

  &.xxs {
    padding: 0.15rem 0.5rem;

    & > .ubutton-text {
      font-size: 0.85rem;
    }

    & > .ubutton-icon {
      height: 0.9rem;
      width: 0.9rem;
      min-width: 0.9rem;
      min-height: 0.9rem;
      object-fit: contain;
    }
  }

  &.xs {
    padding: 0.35rem 0.85rem;

    & > .ubutton-text {
      font-size: 0.85rem;
    }

    & > .ubutton-icon {
      height: 1.1rem;
      width: 1.1rem;
      min-width: 1.1rem;
      min-height: 1.1rem;
      object-fit: contain;
    }
  }

  &.s {
    padding: 0.35rem 1.15rem;

    & > .ubutton-text {
      font-size: 1rem;
    }

    & > .ubutton-icon {
      height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      object-fit: contain;
    }
  }

  &.m {
    padding: 0.8rem 1.45rem;

    & > .ubutton-text {
      font-size: 1rem;
    }

    & > .ubutton-icon {
      height: 1.5rem;
      width: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      object-fit: contain;
    }
  }

  &.l {
    padding: 0.95rem 1.7rem;

    & > .ubutton-text {
      font-size: 1.15rem;
    }

    & > .ubutton-icon {
      height: 1.75rem;
      width: 1.75rem;
      min-width: 1.75rem;
      min-height: 1.75rem;
      object-fit: contain;
    }
  }

  &.full-width {
    width: 100%;
    justify-content: center;
  }
}
</style>
