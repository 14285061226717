<template>
  <input
    type="color"
    :value="modelValue"
    @input="handleInput"
    class="ucolor-picker"
    :style="`--picker-size: ${pickerSize}`"
  />
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '#000000'
  },
  size: {
    type: String,
    default: 's',
    validator: (val) => ['xs', 's', 'm', 'l'].includes(val)
  }
})

const emit = defineEmits(['update:modelValue'])

const pickerSize = computed(() => {
  return {
    xs: '1rem',
    s: '1.5rem',
    m: '2rem',
    l: '2.75rem'
  }[props.size]
})

function handleInput(event) {
  emit('update:modelValue', event.target.value)
}
</script>

<style lang="scss" scoped>
.ucolor-picker {
  border: none;
  background: rgba($black, 0);
  transition: background 0.2s ease;

  min-width: calc(var(--picker-size) + 1rem);
  max-width: calc(var(--picker-size) + 1rem);
  min-height: calc(var(--picker-size) + 1rem);
  max-height: calc(var(--picker-size) + 1rem);

  &::-webkit-color-swatch {
    border-radius: 999rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba($black, 0.25);

    min-width: var(--picker-size);
    max-width: var(--picker-size);
    min-height: var(--picker-size);
    max-height: var(--picker-size);

    border: 2px solid rgba($black, 0.1);

    transition: border 0.2s ease;

    &:hover {
      border: 2px solid rgba($black, 0.25);
    }
  }

  &::-moz-color-swatch {
    border-radius: 999rem;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba($black, 0.25);

    min-width: var(--picker-size);
    max-width: var(--picker-size);
    min-height: var(--picker-size);
    max-height: var(--picker-size);

    border: 2px solid rgba($black, 0.1);

    transition: border 0.2s ease;

    &:hover {
      border: 2px solid rgba($black, 0.25);
    }
  }
}
</style>
