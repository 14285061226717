export const SearchFacet = {
  topics: 'topics',
  types: 'types',
  content_classes: 'content_classes',
  languages: 'languages',
  industries: 'industries',
  organisations: 'organisations',
  created_dates: 'created_dates',
  modified_dates: 'modified_dates',
  mimetypes: 'mimetypes',
  labels: 'labels',
  bookmarks: 'bookmarks',
  resolutions: 'resolutions',
  authors: 'authors',
  editors: 'editors',
  insights: 'insights',
  file_locations: 'file_locations'
}

export const SearchFacetMap = {
  resources: [
    SearchFacet.types,
    SearchFacet.content_classes,
    SearchFacet.topics,
    SearchFacet.organisations,
    SearchFacet.industries,
    SearchFacet.created_dates,
    SearchFacet.modified_dates,
    SearchFacet.languages,
    SearchFacet.authors,
    SearchFacet.mimetypes,
    SearchFacet.labels,
    SearchFacet.bookmarks,
    SearchFacet.editors,
    SearchFacet.file_locations
  ],
  subresources: [
    SearchFacet.topics,
    SearchFacet.organisations,
    SearchFacet.industries,
    SearchFacet.languages,
    SearchFacet.created_dates,
    SearchFacet.modified_dates,
    SearchFacet.labels,
    SearchFacet.bookmarks,
    ...(!import.meta.env.PROD
      ? [SearchFacet.resolutions]
      : [])
  ],
  video: [
    SearchFacet.types,
    SearchFacet.labels,
    SearchFacet.authors,
    SearchFacet.topics,
    SearchFacet.organisations,
    SearchFacet.industries,
    SearchFacet.modified_dates,
    SearchFacet.created_dates,
    SearchFacet.file_locations,
    SearchFacet.bookmarks
  ]
}

export const SearchFacetTitle = {
  [SearchFacet.topics]: 'Topics',
  [SearchFacet.types]: 'Document type',
  [SearchFacet.content_classes]: 'Content class',
  [SearchFacet.languages]: 'Languages',
  [SearchFacet.industries]: 'Industry',
  [SearchFacet.organisations]: 'Organisation',
  [SearchFacet.created_dates]: 'Created',
  [SearchFacet.modified_dates]: 'Last modified',
  [SearchFacet.mimetypes]: 'File type',
  [SearchFacet.labels]: 'Quality labels',
  [SearchFacet.bookmarks]: 'Bookmarks',
  [SearchFacet.resolutions]: 'Resolutions',
  [SearchFacet.authors]: 'Author',
  [SearchFacet.editors]: 'Editors',
  [SearchFacet.insights]: 'Insights',
  [SearchFacet.file_locations]: 'File Location'
}

export const SortType = {
  relevance: 'Relevance',
  modified_date: 'Last Modified',
  creation_date: 'Last Created',
  popularity: 'Popularity'
}

export const SortTypeKey = {
  [SortType.relevance]: 'score_desc',
  [SortType.modified_date]: 'modified_date_desc',
  [SortType.creation_date]: 'created_date_desc',
  [SortType.popularity]: 'popularity_desc'
}

export const SortTypeDefault = {
  search: SortType.relevance,
  workspace: SortType.modified_date,
  video: SortType.creation_date
}
