import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTemplateStore = defineStore('templates', () => {
  const tools = ref([])
  const toolKits = ref([])

  function setTools(t) {
    tools.value = t
  }

  function addTool(tool) {
    let newTools = [...tools.value]
    let idx = newTools.findIndex((t) => t.name > tool.name)
    if (idx === -1) idx = newTools.length
    newTools.splice(idx, 0, tool)
    tools.value = newTools
  }

  function editTool(tool) {
    tools.value = tools.value.map((t) => (t.uuid === tool.uuid ? tool : t))
  }

  function deleteTool(idx) {
    tools.value.splice(idx, 1)
  }

  function resetTools() {
    tools.value = []
  }

  function setToolKits(kits) {
    toolKits.value = kits
  }

  function addToolKit(kit) {
    toolKits.value.push(kit)
  }

  function editToolKit({ toolkit, idx }) {
    toolKits.value.splice(idx, 1, toolkit)
  }

  function deleteToolKit(idx) {
    toolKits.value.splice(idx, 1)
  }

  function resetToolKits() {
    toolKits.value = []
  }

  return {
    tools,
    toolKits,
    setTools,
    addTool,
    editTool,
    deleteTool,
    resetTools,
    setToolKits,
    addToolKit,
    editToolKit,
    deleteToolKit,
    resetToolKits
  }
})
