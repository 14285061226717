<template>
  <Modal
    :visible="visible"
    :title="title"
    submit-text="Import"
    width="60rem"
    @submit="submit"
    @close="close"
  >
    <div class="import-modal">
      <div class="import-modal-header">
        {{ action }}, with these options:
        <ul class="import-modal-header-list">
          <li>
            Import a CSV file with all <b>{{ items }}</b> in the same row or in
            the same column
          </li>
          <li>
            Import a text file with all <b>{{ items }}</b> on a separate line,
            or on the first line, separated by commas
          </li>
          <li>
            Paste or type a list of <b>{{ items }}</b> in the text box below,
            where all {{ items }} are on a separate line, or on the first line,
            separated by commas
          </li>
        </ul>
      </div>
      <UploadDropzone
        :filetypes="['csv', 'txt']"
        min-height="35vh"
        :max-count="1"
        :show-drive="false"
        @upload="handleUpload"
        @remove="removeFile"
      />
      <TextArea
        v-model="textInput"
        max-height="35vh"
        :placeholder="`Paste or type ${items} here`"
      />
    </div>
  </Modal>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Import'
  },
  action: {
    type: String,
    default: 'Import multiple items'
  },
  items: {
    type: String,
    default: 'urls'
  }
})

const emit = defineEmits(['close', 'submit'])

const textInput = ref('')

const inputsFromText = computed(() => {
  return textInput.value
    .split(/[\n,]/)
    .map((i) => i.trim())
    .filter(Boolean)
})

const inputsFromFile = ref([])

function handleUpload({ file }) {
  // use FileReader to read the file and extract the inputs
  const reader = new FileReader()
  reader.onload = (e) => {
    const text = e.target.result
    inputsFromFile.value = text
      .split(/[\n,]/)
      .map((i) => i.trim())
      .filter(Boolean)
  }
  reader.readAsText(file)
}

function removeFile() {
  inputsFromFile.value = []
}

function submit() {
  const inputs = [
    ...inputsFromFile.value,
    ...inputsFromText.value.filter((i) => !inputsFromFile.value.includes(i))
  ]
  emit('submit', inputs)
}

function close() {
  emit('close')
}
</script>

<style lang="scss" scoped>
.import-modal {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    color: $grey;
    padding: 0 1.5rem 0.5rem;

    &-list {
      padding-left: 1rem;
      list-style-type: disc;
    }
  }
}
</style>
