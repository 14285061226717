import { getClient, getResponseData, Paginator } from '@/core/axios'

const client = getClient('/workspaces')

function mapType(type) {
  return type.endsWith('s') ? type : `${type}s`
}

export async function searchORI({
  workspace_id,
  ori_type,
  search,
  attribute_value_ids,
  ordering,
  owner_id,
  page_size,
  callback = () => {}
}) {
  const res = await client.get(`/${workspace_id}/${mapType(ori_type)}/`, {
    params: {
      search,
      attribute_value_ids: attribute_value_ids?.length
        ? attribute_value_ids.join(',')
        : null,
      ordering: ordering || '-date_updated',
      page_size: page_size || 100,
      owner_id
    }
  })
  callback(res)
  return getResponseData(res)
}

export async function getSearchORIPaginator({
  workspace_id,
  ori_type,
  search,
  attribute_value_ids,
  ordering,
  page_size,
  owner_id
}) {
  return new Paginator(
    client,
    await client.get(`/${workspace_id}/${mapType(ori_type)}/`, {
      params: {
        search,
        ordering: ordering || '-date_updated',
        attribute_value_ids: attribute_value_ids?.length
          ? attribute_value_ids.join(',')
          : null,
        page_size: page_size || 10,
        owner_id
      }
    })
  )
}

export async function searchORIExtended({
  workspace_id,
  ori_type,
  query,
  statuses,
  parent_ids,
  owner_ids,
  has_offerings,
  has_references,
  has_inspirations,
  confidentiality,
  page_size,
  export_filetype,
  ...attributes
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/search/`,
    {
      ...(query ? { query } : {}),
      ...(statuses?.length ? { statuses } : {}),
      ...(parent_ids?.length ? { parent_ids } : {}),
      ...(owner_ids?.length ? { owner_ids } : {}),
      ...(has_offerings ? { has_offerings } : {}),
      ...(has_references ? { has_references } : {}),
      ...(has_inspirations ? { has_inspirations } : {}),
      ...(confidentiality ? { confidentiality } : {}),
      ...(Object.keys(attributes || {}).length
        ? {
            attribute_value_ids: Object.keys(attributes).reduce(
              (acc, curr) => [...acc, ...attributes[curr]],
              []
            )
          }
        : {})
    },
    {
      params: {
        page_size: page_size || 10,
        ...(export_filetype ? { export: export_filetype } : {})
      }
    }
  )
  return getResponseData(res)
}

export async function getSearchORIExtendedPaginator({
  workspace_id,
  ori_type,
  query,
  sort_by,
  statuses,
  parent_ids,
  owner_ids,
  has_offerings,
  has_references,
  has_inspirations,
  confidentiality,
  page_size,
  callback = () => {},
  ...attributes
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(sort_by ? { sort_by } : {}),
    ...(statuses?.length ? { statuses } : {}),
    ...(parent_ids?.length ? { parent_ids } : {}),
    ...(owner_ids?.length ? { owner_ids } : {}),
    ...(has_offerings ? { has_offerings } : {}),
    ...(has_references ? { has_references } : {}),
    ...(has_inspirations ? { has_inspirations } : {}),
    ...(confidentiality ? { confidentiality } : {}),
    ...(Object.keys(attributes || {}).length
      ? {
          attribute_value_ids: Object.keys(attributes).reduce(
            (acc, curr) => [...acc, ...attributes[curr]],
            []
          )
        }
      : {})
  }
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/search/`,
    body,
    {
      params: {
        page_size: page_size || 10
      }
    }
  )
  callback(res)
  return new Paginator(
    client,
    res,
    () => {},
    '',
    (url) => client.post(url, body)
  )
}

export async function getORI({ workspace_id, ori_type, ori_id, act_as }) {
  const res = await client.get(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/`,
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function createORI({
  workspace_id,
  ori_type,
  name,
  custom_id,
  category_id,
  parent_id,
  owner_ids,
  account_id,
  confidentiality,
  offering_ids,
  reference_ids,
  inspiration_ids,
  state,
  integration_file_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/`,
    {
      name,
      custom_id,
      category_id,
      parent_id,
      owner_ids,
      account_id,
      confidentiality,
      offering_ids,
      reference_ids,
      inspiration_ids,
      state,
      integration_file_id
    },
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function editORI({
  workspace_id,
  ori_type,
  ori_id,
  name,
  custom_id,
  category_id,
  parent_id,
  owner_ids,
  account_id,
  confidentiality,
  offering_ids,
  reference_ids,
  inspiration_ids,
  webpage_urls,
  draft,
  status,
  position,
  attribute_value_ids,
  act_as
}) {
  const res = await client.patch(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/`,
    {
      name,
      custom_id,
      category_id,
      parent_id,
      owner_ids,
      account_id,
      confidentiality,
      offering_ids,
      reference_ids,
      inspiration_ids,
      webpage_urls,
      draft,
      status,
      position,
      attribute_value_ids
    },
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function deleteORI({ workspace_id, ori_type, ori_id }) {
  const res = await client.delete(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/`
  )
  return getResponseData(res)
}

export async function generateORIImage({
  workspace_id,
  ori_type,
  ori_id,
  amount
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/image/generate/`,
    {},
    { params: { amount } }
  )
  return getResponseData(res)
}

export async function setORIImage({ workspace_id, ori_type, ori_id, image }) {
  let formData = null
  if (image instanceof File) {
    formData = new FormData()
    formData.append('image', image)
  }
  const res = await client.put(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/image/`,
    formData
  )
  return getResponseData(res)
}

export async function editORIResourceLink({
  workspace_id,
  ori_type,
  ori_id,
  resource_id,
  attribute_value_ids,
  position,
  act_as
}) {
  const res = await client.put(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/resources/${resource_id}/`,
    {
      attribute_value_ids,
      position
    },
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function regenerateORIResource({
  workspace_id,
  ori_type,
  ori_id,
  template_id,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/templates/${template_id}/regenerate/`,
    {},
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function unlinkResourceFromORI({
  workspace_id,
  ori_type,
  ori_id,
  resource_id
}) {
  const res = await client.delete(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/resources/${resource_id}/`
  )
  return getResponseData(res)
}

export async function getORIResourceUploadProps({
  workspace_id,
  ori_type,
  ori_id,
  name,
  mimetype,
  content_length,
  act_as
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/assets/upload/`,
    {
      name,
      mimetype,
      content_length
    },
    {
      params: { act_as }
    }
  )
  return getResponseData(res)
}

export async function processORIResourceUpload({
  workspace_id,
  ori_type,
  ori_id,
  state,
  integration_file_id
}) {
  const res = await client.post(
    `/${workspace_id}/${mapType(ori_type)}/${ori_id}/assets/process/`,
    {
      state,
      integration_file_id
    }
  )
  return getResponseData(res)
}

export async function getORIAnalytics({
  workspace_id,
  ori_type,
  export_filetype
}) {
  const res = await client.get(
    `/${workspace_id}/${mapType(ori_type)}/analytics/`,
    {
      params: {
        ...(export_filetype ? { export: export_filetype } : {})
      }
    }
  )
  return res
}

// OFFERING CATEGORIES

export async function getOfferingCategories({ workspace_id, extended }) {
  const res = await client.get(`/${workspace_id}/offerings/categories/`, {
    ...(extended ? { params: { extended } } : {})
  })
  return getResponseData(res)
}

export async function createOfferingCategory({
  workspace_id,
  name,
  description
}) {
  const res = await client.post(`/${workspace_id}/offerings/categories/`, {
    name,
    description
  })
  return getResponseData(res)
}

export async function editOfferingCategory({
  workspace_id,
  category_id,
  name,
  description,
  position
}) {
  const res = await client.patch(
    `/${workspace_id}/offerings/categories/${category_id}/`,
    {
      name,
      description,
      position
    }
  )
  return getResponseData(res)
}

export async function deleteOfferingCategory({ workspace_id, category_id }) {
  const res = await client.delete(
    `/${workspace_id}/offerings/categories/${category_id}/`
  )
  return getResponseData(res)
}
