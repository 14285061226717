export const colorCodes = {
  primary: '#0037ff',
  'primary-mid': '#6999fc',
  'primary-light': '#ebf1ff',
  dark: '#303032',
  grey: '#60666b',
  'grey-700': '#303032',
  'grey-600': '#60666b',
  'grey-500': '#8f9399',
  'grey-400': '#dddfe2',
  'grey-300': '#e9ebed',
  'grey-200': '#f1f2f3',
  'grey-100': '#f9f9fa',
  red: '#fc0d1b',
  'red-light': '#fdd',
  'red-dark': '#c00',
  orange: '#f29203',
  green: '#24ce85',
  'green-light': '#e6f9f0',
  'green-dark': '#166c4b',
  purple: '#8937df',
  pink: '#da42d4',
  yellow: '#fddb29',
  'yellow-light': '#FFFDB5',
  'yellow-dark': '#808009',
  'border-color': 'rgba(#000, 0.08)',
  black: '#000',
  white: '#fff',
  transparent: 'transparent'
}

export const colors = Object.keys(colorCodes)

export const mapColor = (color) => colorCodes[color] || color

export const additionalColors = [
  'rgb(247, 159, 31)',
  'rgb(163, 203, 56)',
  'rgb(18, 137, 167)',
  'rgb(217, 128, 250)',
  'rgb(181, 52, 113)',
  'rgb(255, 195, 18)',
  'rgb(196, 229, 56)',
  'rgb(18, 203, 196)',
  'rgb(253, 167, 223)',
  'rgb(237, 76, 103)',
  'rgb(238, 90, 36)',
  'rgb(0, 148, 50)',
  'rgb(6, 82, 221)',
  'rgb(153, 128, 250)',
  'rgb(131, 52, 113)',
  'rgb(234, 32, 39)',
  'rgb(0, 98, 102)',
  'rgb(27, 20, 100)',
  'rgb(87, 88, 187)',
  'rgb(111, 30, 81)',
  'rgb(255, 99, 132)',
  'rgb(54, 162, 235)',
  'rgb(255, 205, 86)'
]

export const pastelColors = [
  '#a6bd94',
  '#ccb68c',
  '#91b4fd',
  '#b49bfd',
  '#d89bfd',
  '#fd9be8',
  '#fe9aa0',
  '#fdac9b',
  '#fcad4f',
  '#fccf36',
  '#7cdfb4'
]
