import { getClient, getResponseData } from '@/core/axios'
import { useUserStore } from '@/stores/user'

const client = getClient('/users')

export async function getCurrentUser() {
  const res = await client.get('/me/')
  return getResponseData(res)
}

export async function editCurrentUser(data) {
  const res = await client.patch('/me/', data)
  const user = getResponseData(res)
  const store = useUserStore()
  store.setCurrentUser(user)
  return user
}

export async function editCurrentUserAvatar(avatar) {
  let formData = null
  if (avatar instanceof File) {
    formData = new FormData()
    formData.append('avatar', avatar)
  }
  const res = await client.put('/me/avatar/', formData)
  const data = getResponseData(res)
  const store = useUserStore()
  store.setCurrentUser(data)
  return data
}

export async function getUserFileUploadProps({
  name,
  mimetype,
  content_length
}) {
  const res = await client.post('/me/files/upload/', {
    name,
    mimetype,
    content_length
  })
  return getResponseData(res)
}
