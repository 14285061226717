import { computed } from 'vue'
import { useUserStore } from '@/stores/user'
import { useSettingsStore } from '@/stores/settings'
import { useWorkspaceStore } from '@/stores/workspace'

// Checklist for adding a new view:
// - [ ] Create a component in the views folder
// - [ ] Create an entry in the router index using that component
// - [ ] Add an entry with the required user permissions in the core/routes.js file
// - [ ] Add an entry in GlobalSidebar
// - [ ] Add a header in GlobalHeader

export function useViewAuth() {
  const authenticatedRoutes = computed(() => {
    const user = useUserStore()
    const settings = useSettingsStore()
    const workspace = useWorkspaceStore()

    return {
      home: true,
      search: user.canUseSearch,
      meetings: user.canWriteStories,
      chat: user.canWriteChats,
      portfolio: user.canWriteOri || user.canReadOri,
      prospecting: user.canUseProspecting,
      bizdev: user.canReadAccountLists && user.canWriteAccountLists,
      lists: user.canReadAccountLists,
      presentations: workspace.showCollectSlides && user.canUseSearch,
      analytics:
        user.canWriteMembers || user.canSearchStories || user.canWriteOri,
      curation: user.canWriteLabels,
      settings: !!settings.settingsTabs.length,
      onboarding: user.onboardingRequired
    }
  })

  const availableRoutes = computed(() =>
    Object.keys(authenticatedRoutes.value).filter(
      (route) => authenticatedRoutes.value[route]
    )
  )

  const childRoutes = {
    meetings: ['meeting-outputs'],
    portfolio: ['portfolio-detail']
  }

  const authenticatedRoutesWithChildren = computed(() => {
    const routes = { ...authenticatedRoutes.value }
    Object.keys(childRoutes).forEach((route) => {
      if (routes[route]) {
        childRoutes[route].forEach((child) => {
          routes[child] = true
        })
      }
    })
    return routes
  })

  const availableRoutesWithChildren = computed(() =>
    Object.keys(authenticatedRoutesWithChildren.value).filter(
      (route) => authenticatedRoutesWithChildren.value[route]
    )
  )

  return {
    authenticatedRoutes,
    availableRoutes,
    authenticatedRoutesWithChildren,
    availableRoutesWithChildren
  }
}
