import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from './workspace'

export const useLanguageStore = defineStore('language', () => {
  const userStore = useUserStore()
  const workspaceStore = useWorkspaceStore()

  const supportedLanguages = ref([
    { name: 'Dansk', code: 'da' },
    { name: 'Deutsch', code: 'de' },
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: 'Français', code: 'fr' },
    { name: 'Hrvatski', code: 'hr' },
    { name: 'Italiano', code: 'it' },
    { name: 'Nederlands', code: 'nl' },
    { name: 'Norsk', code: 'no' },
    { name: 'Português', code: 'pt' },
    { name: 'Svenska', code: 'sv' },
    { name: 'Türkçe', code: 'tr' },
    { name: '中文', code: 'zh-hans' }
  ])

  const defaultLanguage = computed(() => {
    return (
      userStore.currentUser?.language ||
      workspaceStore.currentWorkspace?.language ||
      'en'
    )
  })

  return {
    supportedLanguages,
    defaultLanguage
  }
})
