<template>
  <div v-if="tasks.length" class="sidebar-queue-wrapper">
    <div class="sidebar-queue" @click="openModal">
      <Icon
        name="ai"
        fill="primary"
        class="sidebar-queue-icon"
        :class="{ spinning: tasksRunning.length }"
      />
      <Tag
        v-if="latestTasksCompleted"
        :text="`${latestTasksCompleted}`"
        size="xxs"
        class="sidebar-queue-tag"
      />
    </div>
    <div v-if="tasks.length" class="sidebar-queue-tooltip">
      <div
        v-for="(item, idx) in tooltip"
        :key="`queue-tooltip-${idx}`"
        class="sidebar-queue-tooltip-item"
      >
        <Icon
          :name="item.icon"
          :fill="item.fill"
          class="sidebar-queue-tooltip-icon"
        />
        <p class="sidebar-queue-tooltip-count">{{ item.count }}</p>
        <p class="sidebar-queue-tooltip-text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue'

const queue = inject('$queue')

const tasks = computed(() => queue.tasks.value)

const tasksRunning = computed(() =>
  tasks.value.filter((task) => !task.finished)
)

const tasksCompleted = computed(() =>
  tasks.value.filter((task) => task.finished && !task.errored)
)

const tasksErrored = computed(() => tasks.value.filter((task) => task.errored))

const tooltip = computed(() => {
  return [
    ...(tasksRunning.value.length
      ? [
          {
            icon: 'refresh',
            fill: 'primary',
            count: tasksRunning.value.length,
            text: `running task${tasksRunning.value.length === 1 ? '' : 's'}`
          }
        ]
      : []),
    ...(tasksCompleted.value.length
      ? [
          {
            icon: 'check-circle',
            fill: 'green',
            count: tasksCompleted.value.length,
            text: `completed task${tasksCompleted.value.length === 1 ? '' : 's'}`
          }
        ]
      : []),
    ...(tasksErrored.value.length
      ? [
          {
            icon: 'check-warning',
            fill: 'red',
            count: tasksErrored.value.length,
            text: `errored task${tasksErrored.value.length === 1 ? '' : 's'}`
          }
        ]
      : [])
  ]
})

const latestTasksCompleted = computed(() => queue.latestTasksCompleted.value)

function openModal() {
  queue.openModal()
}
</script>

<style lang="scss" scoped>
.sidebar-queue {
  &-wrapper {
    position: relative;

    &:hover {
      .sidebar-queue-tooltip {
        display: flex;
        opacity: 1;
      }
    }
  }

  padding: 0.5rem;
  cursor: pointer;
  border-radius: 999rem;
  transition: background 0.2s ease;
  background: rgba($primary-light, 0.75);

  &:hover {
    background: rgba($primary-light, 1);
  }

  &-icon {
    height: 1.75rem;
    width: 1.75rem;
    &.spinning {
      animation: spin 1.5s ease-in-out infinite;
    }
  }

  &-tag {
    position: absolute;
    top: -0.5rem;
    right: -0.75rem;
  }

  &-tooltip {
    position: absolute;
    left: calc(100% + 0.5rem);
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    background: $white;
    border-radius: 6px;
    box-shadow: 0 0 8px rgba($black, 0.16);
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease;
    flex-flow: column nowrap;
    gap: 1rem;
    padding: 0.75rem 1.5rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-icon {
      width: 1.3rem;
    }

    &-count {
      font-weight: 700;
    }

    &-text {
      white-space: nowrap;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
