import { Paginator, getClient, getResponseData } from '@/core/axios'
import { useTemplateStore } from '@/stores/templates'

const client = getClient('/workspaces')

// TEMPLATES

export async function getTemplates({ workspace_id }) {
  const res = await client.get(`/${workspace_id}/templates/`)
  return getResponseData(res)
}

export async function getTemplatePaginator({ workspace_id, template_id }) {
  return new Paginator(
    client,
    await client.get(`/${workspace_id}/templates/${template_id}/`)
  )
}

export async function createTemplate({
  workspace_id,
  output_type,
  name,
  description,
  mimetype,
  content_length,
  status,
  template_id
}) {
  const res = await client.post(`/${workspace_id}/templates/`, {
    ...(output_type ? { output_type } : {}),
    ...(name ? { name } : {}),
    ...(description ? { description } : {}),
    ...(mimetype ? { mimetype } : {}),
    ...(content_length ? { content_length } : {}),
    ...(status ? { status } : {}),
    ...(template_id ? { template_id } : {})
  })
  return getResponseData(res)
}

export async function editTemplate({
  workspace_id,
  template_id,
  name,
  description,
  status,
  review_period
}) {
  const res = await client.patch(`/${workspace_id}/templates/${template_id}/`, {
    name,
    description,
    status,
    review_period
  })
  return getResponseData(res)
}

export async function deleteTemplate({ workspace_id, template_id }) {
  const res = await client.delete(`/${workspace_id}/templates/${template_id}/`)
  return getResponseData(res)
}

export async function parseTemplateInput({ workspace_id, template_id }) {
  const res = await client.get(
    `/${workspace_id}/presentations/${template_id}/parse/`
  )
  return getResponseData(res)
}

export async function getTemplateSlides({ workspace_id, template_id }) {
  const res = await client.get(
    `/${workspace_id}/presentations/${template_id}/slides/`
  )
  return getResponseData(res)
}

export async function editTemplateShape({
  workspace_id,
  template_id,
  slide_id,
  shape_id,
  prompt,
  image
}) {
  const res = await client.patch(
    `/${workspace_id}/presentations/${template_id}/slides/${slide_id}/shapes/${shape_id}/`,
    {
      ...(prompt || prompt === '' ? { prompt } : {}),
      ...(image || image === '' ? { image } : {})
    }
  )
  return getResponseData(res)
}

export async function testTemplate({
  workspace_id,
  template_id,
  output_type,
  output_id
}) {
  const res = await client.post(
    `/${workspace_id}/presentations/${template_id}/test/`,
    {
      output_type,
      output_id
    }
  )
  return getResponseData(res)
}

export async function testTemplateShape({
  workspace_id,
  template_id,
  slide_id,
  shape_id,
  output_type,
  output_id
}) {
  const res = await client.post(
    `/${workspace_id}/presentations/${template_id}/slides/${slide_id}/shapes/${shape_id}/test/`,
    {
      output_type,
      output_id
    }
  )
  return getResponseData(res)
}

export async function createTemplateBlock({
  workspace_id,
  template_id,
  slide_index,
  position,
  output_type,
  selections
}) {
  const res = await client.post(
    `/${workspace_id}/presentations/${template_id}/blocks/`,
    {
      slide_index,
      position,
      output_type,
      selections
    }
  )
  return getResponseData(res)
}

export async function editTemplateBlock({
  workspace_id,
  template_id,
  block_id,
  slide_index,
  position,
  selections
}) {
  const res = await client.patch(
    `/${workspace_id}/presentations/${template_id}/blocks/${block_id}/`,
    {
      slide_index,
      position,
      selections
    }
  )
  return getResponseData(res)
}

export async function deleteTemplateBlock({
  workspace_id,
  template_id,
  block_id
}) {
  const res = await client.delete(
    `/${workspace_id}/presentations/${template_id}/blocks/${block_id}/`
  )
  return getResponseData(res)
}

export async function getTemplateResources({ workspace_id, template_id }) {
  const res = await client.get(
    `/${workspace_id}/templates/${template_id}/files/`
  )
  return getResponseData(res)
}

export async function editTemplateResource({
  workspace_id,
  template_id,
  ori_id,
  ori_type,
  status,
  act_as = undefined
}) {
  const res = await client.patch(
    `/${workspace_id}/${ori_type}/${ori_id}/templates/${template_id}/`,
    {
      status,
      act_as
    }
  )
  return getResponseData(res)
}

export async function getTemplatePublishORIs({
  workspace_id,
  template_id,
  ori_type
}) {
  const res = await client.get(
    `/${workspace_id}/templates/${template_id}/${ori_type}/`
  )
  return res
}

export async function setTemplatePublishORIs({
  workspace_id,
  template_id,
  ori_type,
  filters,
  page_size
}) {
  const res = await client.put(
    `/${workspace_id}/templates/${template_id}/${ori_type}/`,
    filters,
    {
      params: {
        page_size: page_size
      }
    }
  )
  return res
}

// TOOLS

export async function getTools({ workspace_id }) {
  const response = await client.get(`/${workspace_id}/tools/`)
  const tools = getResponseData(response)
  const store = useTemplateStore()
  store.setTools(tools)
  return tools
}

export async function getTool({ workspace_id, tool_id }) {
  const response = await client.get(`/${workspace_id}/tools/${tool_id}/`)
  return getResponseData(response)
}

export async function createTool({
  workspace_id,
  name,
  description,
  prompt,
  status,
  template_id,
  tool_id,
  type
}) {
  const response = await client.post(`/${workspace_id}/tools/`, {
    name,
    description,
    content: prompt,
    status,
    template_id,
    tool_id,
    type
  })
  const tool = getResponseData(response)
  const store = useTemplateStore()
  store.addTool(tool)
  return tool
}

export async function editTool({
  workspace_id,
  tool_id,
  name,
  description,
  prompt,
  status
}) {
  const response = await client.patch(`/${workspace_id}/tools/${tool_id}/`, {
    name,
    description,
    content: prompt,
    status
  })
  const tool = getResponseData(response)
  const store = useTemplateStore()
  store.editTool(tool)
  return tool
}

export async function deleteTool({ workspace_id, tool_id }) {
  const response = await client.delete(`/${workspace_id}/tools/${tool_id}/`)
  const data = getResponseData(response)
  const store = useTemplateStore()
  const idx = store.tools.findIndex((x) => x.uuid === tool_id)
  store.deleteTool(idx)
  return data
}

// GLOBAL TOOLS

export async function getGlobalToolsPaginator({
  query,
  types,
  provider_ids,
  tag_ids,
  sort_by,
  callback = () => {},
  page_size = 15
}) {
  const rootClient = getClient('/tools')
  const body = {
    ...(query && { query }),
    types,
    provider_ids,
    tag_ids,
    sort_by
  }
  const res = await rootClient.post('/search/', body, { params: { page_size } })
  callback(res)
  return new Paginator(
    rootClient,
    res,
    () => {},
    '',
    (url) => rootClient.post(url, body)
  )
}

export async function getGlobalToolsPage({
  query,
  type,
  provider_ids,
  tag_ids,
  sort_by,
  page_size = 99
}) {
  const rootClient = getClient('/tools')
  const res = await rootClient.post(
    '/search/',
    {
      ...(query && { query }),
      type,
      provider_ids,
      tag_ids,
      sort_by
    },
    { params: { page_size } }
  )
  return getResponseData(res)
}

export async function getGlobalToolSuggestions() {
  const rootClient = getClient('/tools')
  const res = await rootClient.get('/suggestions/')
  return getResponseData(res)
}

// SALES KITS

export async function getToolKits({ workspace_id }) {
  const store = useTemplateStore()
  if (store.toolKits?.length) return store.toolKits
  const response = await client.get(`/${workspace_id}/toolkits/`)
  const tks = getResponseData(response)
  store.setToolKits(tks)
  return tks
}

export async function getToolKit({ workspace_id, toolkit_id }) {
  const response = await client.get(`/${workspace_id}/toolkits/${toolkit_id}/`)
  return getResponseData(response)
}

export async function createToolKit({
  workspace_id,
  name,
  description,
  status,
  template_ids
}) {
  const response = await client.post(`/${workspace_id}/toolkits/`, {
    name,
    description,
    tool_ids: template_ids,
    status
  })
  const tk = getResponseData(response)
  const store = useTemplateStore()
  store.addToolKit(tk)
  return tk
}

export async function editToolKit({
  workspace_id,
  toolkit_id,
  name,
  description,
  status,
  template_ids
}) {
  const response = await client.patch(
    `/${workspace_id}/toolkits/${toolkit_id}/`,
    {
      name,
      description,
      tool_ids: template_ids,
      status
    }
  )
  const tk = getResponseData(response)
  const store = useTemplateStore()
  const idx = store.toolKits.findIndex((x) => x.uuid === tk.uuid)
  store.editToolKit({ idx, toolkit: tk })
  return tk
}

export async function deleteToolKit({ workspace_id, toolkit_id }) {
  const response = await client.delete(
    `/${workspace_id}/toolkits/${toolkit_id}/`
  )
  const data = getResponseData(response)
  const store = useTemplateStore()
  const idx = store.toolKits.findIndex((x) => x.uuid === toolkit_id)
  store.deleteToolKit(idx)
  return data
}

export async function duplicateToolKit({ workspace_id, toolkit_id }) {
  const master = await getToolKit({
    workspace_id,
    toolkit_id
  })
  return createToolKit({
    workspace_id,
    name: `${master.name} (copy)`,
    description: master.description,
    template_ids: master.tools.map((x) => x.uuid)
  })
}
