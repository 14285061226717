<template>
  <Modal
    :visible="visible"
    :disabled="!domain"
    :loading="loading"
    title="Please input your client's website"
    submit-text="Save"
    @submit="handleSubmit"
    @close="close"
  >
    <p class="domain-input-header">
      We couldn't find your client's company website, please paste it here
    </p>
    <TextInput
      v-model="domain"
      placeholder="Website url"
      @submit="handleSubmit"
    />
  </Modal>
</template>

<script>
export default {
  name: 'OrganisationDomainInput',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    domain: ''
  }),
  methods: {
    close() {
      this.$emit('close')
    },
    handleSubmit() {
      if (!this.domain) return
      this.$emit('submit', this.domain)
    }
  }
}
</script>

<style lang="scss" scoped>
.domain-input {
  &-header {
    color: $grey;
  }
}
</style>
