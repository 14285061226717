import { uniqBy } from 'lodash'
import Console from '@/core/console'
import { getClient, getResponseData, Paginator } from '@/core/axios'

const organisationClient = getClient('/organisations')
const workspaceClient = getClient('/workspaces')

// ORGANISATIONS

export async function getOrganisations({ query, page_size = 10 }) {
  const response = await organisationClient.get(`/`, {
    params: { search: query, page_size }
  })
  return getResponseData(response)
}

export async function getOrganisation({ organisation_id }) {
  const response = await organisationClient.get(`/${organisation_id}/`)
  return getResponseData(response)
}

export async function getOrganisationSuggestions({ query }) {
  const response = await organisationClient.get(`/suggestions/`, {
    params: { search: query }
  })
  return getResponseData(response)
}

export async function searchOrganisations({
  query,
  include_suggestions = true
}) {
  let companies = []

  if (!query) {
    return companies
  }

  const [organisationsResponse, organisationSuggestions] = await Promise.all(
    [
      getOrganisations({ query }),
      include_suggestions
        ? getOrganisationSuggestions({ query })
        : new Promise((res) => res([]))
    ].map((p) =>
      p.catch((e) => {
        Console.debug('error in searchOrganisations', e)
        return e
      })
    )
  )
  const isValid = (res) =>
    !!res && !(res instanceof Error) && Array.isArray(res)

  companies = uniqBy(
    [
      ...companies,
      ...(isValid(organisationsResponse) ? organisationsResponse : [])
    ],
    'linkedin_url'
  )

  companies = uniqBy(
    [
      ...companies,
      ...(isValid(organisationSuggestions) ? organisationSuggestions : [])
    ],
    'linkedin_url'
  )
  return companies
}

export async function getOrganisationsSearchPaginator({
  query,
  organisation_id,
  industries,
  headquarters,
  employee_counts,
  is_account,
  ambassadors,
  page_size,
  callback = () => {}
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(organisation_id ? { organisation_id } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(is_account ? { is_account } : {}),
    ...(ambassadors ? { ambassadors } : {})
  }
  const queryParam = {
    ...(page_size ? { page_size } : {})
  }
  const res = await organisationClient.post(`/search/`, body, {
    params: queryParam
  })
  callback(res)
  return new Paginator(
    organisationClient,
    res,
    () => {},
    undefined,
    (url) => organisationClient.post(url, body)
  )
}

export async function getOrganisationsSearchPage({
  query,
  organisation_id,
  industries,
  headquarters,
  employee_counts,
  is_account,
  ambassadors,
  page_size,
  export_filetype,
  callback = () => {}
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(organisation_id ? { organisation_id } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(is_account ? { is_account } : {}),
    ...(ambassadors ? { ambassadors } : {})
  }
  const queryParam = {
    ...(page_size ? { page_size } : {}),
    ...(export_filetype ? { export: export_filetype } : {})
  }
  const res = await organisationClient.post(`/search/`, body, {
    params: queryParam
  })
  callback(res)
  return getResponseData(res)
}

// ACCOUNTS

export async function getAccounts({ workspace_id, query, page, pageSize }) {
  const response = await workspaceClient.get(`/${workspace_id}/accounts/`, {
    params: { search: query, page, pageSize }
  })
  return getResponseData(response)
}

export async function getAccountNews({ workspace_id, account_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/news/`
  )
  return getResponseData(res)
}

export async function createAccount({
  workspace_id,
  linkedin_url,
  domain = undefined
}) {
  const { data } = await workspaceClient.post(`/${workspace_id}/accounts/`, {
    linkedin_url,
    ...(domain ? { domain } : {})
  })
  return data
}

export async function createAccountNote({ workspace_id, account_id, note }) {
  const response = await workspaceClient.post(
    `/${workspace_id}/accounts/${account_id}/notes/`,
    note
  )
  return getResponseData(response)
}

export async function patchAccountNote({
  workspace_id,
  account_id,
  note_id,
  note
}) {
  const response = await workspaceClient.patch(
    `/${workspace_id}/accounts/${account_id}/notes/${note_id}/`,
    note
  )
  return getResponseData(response)
}

export async function removeAccountNote({ workspace_id, account_id, note_id }) {
  const response = await workspaceClient.delete(
    `/${workspace_id}/accounts/${account_id}/notes/${note_id}/`
  )
  return getResponseData(response)
}

export async function getAccountPersons({ workspace_id, account_id, query }) {
  const response = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/persons/`,
    {
      params: { search: query }
    }
  )
  return getResponseData(response)
}

export async function getAccountPersonSuggestions({
  workspace_id,
  account_id
}) {
  const response = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/persons/suggestions/`
  )
  return getResponseData(response)
}

export async function getAccountPersonContacts({
  workspace_id,
  account_id,
  person_id
}) {
  const response = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/persons/${person_id}/contact-info/`
  )
  return getResponseData(response)
}

// ACCOUNT LISTS

export async function getAccountLists({ workspace_id, type }) {
  const res = await workspaceClient.get(`/${workspace_id}/account-lists/`, {
    params: {
      type
    }
  })
  return getResponseData(res)
}

export async function removeAccountList({ workspace_id, account_list_id }) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/account-lists/${account_list_id}/`
  )
  return getResponseData(res)
}

export async function addAccountListItems({
  workspace_id,
  account_list_id,
  linkedin_urls
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/account-lists/${account_list_id}/items/`,
    {
      linkedin_urls
    }
  )
  return getResponseData(res)
}

export async function getAccountListItemsPaginator({
  workspace_id,
  account_list_id,
  page_size,
  query,
  industries,
  headquarters,
  employee_counts,
  ambassadors,
  offering_ids,
  statuses,
  sort_by,
  callback = () => {}
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(ambassadors ? { ambassadors } : {}),
    ...(offering_ids ? { offering_ids } : {}),
    ...(statuses ? { statuses } : {}),
    ...(sort_by ? { sort_by } : {})
  }

  const res = await workspaceClient.post(
    `/${workspace_id}/account-lists/${account_list_id}/items/search/`,
    body,
    {
      params: {
        page_size
      }
    }
  )
  callback(res)
  return new Paginator(
    workspaceClient,
    res,
    () => {},
    undefined,
    (url) => workspaceClient.post(url, body)
  )
}

export async function getAccountListItemsPage({
  workspace_id,
  account_list_id,
  page_size,
  query,
  industries,
  headquarters,
  employee_counts,
  ambassadors,
  offering_ids,
  statuses,
  sort_by,
  export_filetype
}) {
  const body = {
    ...(query ? { query } : {}),
    ...(industries ? { industries } : {}),
    ...(headquarters ? { headquarters } : {}),
    ...(employee_counts ? { employee_counts } : {}),
    ...(ambassadors ? { ambassadors } : {}),
    ...(offering_ids ? { offering_ids } : {}),
    ...(statuses ? { statuses } : {}),
    ...(sort_by ? { sort_by } : {})
  }

  const res = await workspaceClient.post(
    `/${workspace_id}/account-lists/${account_list_id}/items/search/`,
    body,
    {
      params: {
        page_size,
        export: export_filetype
      }
    }
  )
  return getResponseData(res)
}

export async function getAccountListItem({
  workspace_id,
  account_list_id,
  account_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/account-lists/${account_list_id}/items/${account_id}/`
  )
  return getResponseData(res)
}

export async function setAccountListItemStatus({
  workspace_id,
  account_list_id,
  account_id,
  status
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/account-lists/${account_list_id}/items/${account_id}/`,
    {
      status
    }
  )
  return getResponseData(res)
}

export async function getAccountOpportunity({
  workspace_id,
  account_id,
  opportunity_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/opportunities/${opportunity_id}/`
  )
  return getResponseData(res)
}

export async function getAccountStakeholder({
  workspace_id,
  account_id,
  opportunity_id,
  stakeholder_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/accounts/${account_id}/opportunities/${opportunity_id}/stakeholders/${stakeholder_id}/`
  )
  return getResponseData(res)
}

export async function addAccountStakeholder({
  workspace_id,
  account_id,
  opportunity_id,
  linkedin_id
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/accounts/${account_id}/opportunities/${opportunity_id}/stakeholders/`,
    {
      linkedin_id
    }
  )
  return getResponseData(res)
}
