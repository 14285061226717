<template>
  <Dropdown
    :items="dropdownOptions"
    :disabled="disabled"
    :select-closes="false"
    max-height="50vh"
    class="column-select-dropdown"
  >
    <template #trigger>
      <Tooltip text="Select columns" position="left">
        <Button icon="eye-open" type="grey" class="column-select-trigger" />
      </Tooltip>
    </template>
    <template #item="{ item }">
      <div class="column-select-item">
        <Checkbox
          :value="selected.includes(item.id)"
          class="column-select-item-check"
        />
        {{ item.title }}
      </div>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: 'TableColumnSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select'],
  computed: {
    dropdownOptions() {
      return this.options.map((option) => ({
        ...option,
        callback: () => this.handleSelect(option)
      }))
    }
  },
  methods: {
    handleSelect(option) {
      const newSelection = this.options.reduce((acc, curr) => {
        if (curr.id === option.id) {
          return this.selected.includes(curr.id) ? acc : [...acc, curr.id]
        }
        return this.selected.includes(curr.id) ? [...acc, curr.id] : acc
      }, [])
      this.$emit('select', newSelection)
    }
  }
}
</script>

<style lang="scss" scoped>
.column-select {
  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    min-width: min(15rem, 85vw);
    padding: 0.25rem 0;
    font-weight: 400;

    &-check {
      pointer-events: none;
    }
  }
}
</style>
