<template>
  <Modal
    title="Task queue"
    :visible="visible"
    :show-footer="false"
    width="75rem"
    @close="close"
  >
    <Table :items="tasks" :headers="headers" :sortable="false">
      <template #item="{ item, header }">
        <p v-if="header.id === 'task'" class="task-modal-name">
          {{ header.key(item) }}
        </p>
        <Tooltip
          v-else-if="header.id === 'status'"
          :active="header.key(item) === 'error'"
          :text="errorText(item)"
          :append-to-body="true"
        >
          <div
            class="task-modal-status"
            :class="{ hoverable: header.key(item) === 'error' }"
          >
            <Icon
              :name="statusIcon(header.key(item))"
              :fill="iconFill(header.key(item))"
              class="task-modal-status-icon"
            />
            {{ header.key(item).capitalize() }}
          </div>
        </Tooltip>
        <div v-else-if="header.id === 'actions'" class="task-modal-actions">
          <Button
            v-if="header.key(item) === 'cta' && item.cta(item.result)"
            :text="item.cta(item.result)"
            @click="() => cta(item)"
          />
        </div>
      </template>
    </Table>
  </Modal>
</template>

<script setup>
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  tasks: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['close'])

function close() {
  emit('close')
}

function cta(item) {
  item.callback(item.result)
  close()
}

const headers = [
  {
    id: 'task',
    title: 'Task',
    key: (t) =>
      t.finished ? (t.errored ? t.error : t.done(t.result)) : t.loading
  },
  {
    id: 'status',
    title: 'Status',
    key: (t) => (t.finished ? (t.errored ? 'error' : 'done') : 'loading')
  },
  {
    id: 'actions',
    title: '',
    key: (t) => (t.finished && !t.errored ? 'cta' : '')
  }
]

function statusIcon(status) {
  return {
    loading: 'refresh',
    done: 'check-circle',
    error: 'check-warning'
  }[status]
}

function iconFill(status) {
  return {
    loading: 'primary',
    done: 'green',
    error: 'red'
  }[status]
}

function errorText(item) {
  if (
    [400, 404].includes(item.errored?.status) &&
    !!item.errored?.data?.detail
  )
    return item.errored.data.detail
  return 'Something went wrong. Please try again later or contact support.'
}
</script>

<style lang="scss" scoped>
.task-modal {
  &-name {
    font-weight: 600;
    min-width: 30rem;
  }

  &-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    padding: 0.1rem 0;
    cursor: default;

    &.hoverable {
      border-bottom: 1px dotted $grey;
    }

    &-icon {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
