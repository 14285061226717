<template>
  <Dropdown
    ref="filterdropdown"
    :position-fixed="positionFixed"
    @hide="setSelection"
  >
    <template #trigger>
      <div class="filterdropdown-trigger" :class="{ equalized: equalWidth }">
        {{ filter.title }}
        <Tag
          v-if="selected.length"
          :text="`${selected.length}`"
          type="light-solid"
          size="xxs"
          class="filterdropdown-trigger-count"
        />
        <Icon
          name="chevron-down-medium"
          class="filterdropdown-trigger-chevron"
        />
      </div>
    </template>
    <template #content>
      <div class="filterdropdown-content">
        <div
          v-if="filter.options.length > 10"
          class="filterdropdown-content-header"
        >
          <TextInput
            v-model="query"
            :placeholder="`Find ${filter.title.toLowerCase()}`"
          />
        </div>
        <div
          class="filterdropdown-content-items"
          :style="`--dropdown-content-height: ${maxHeight};`"
        >
          <div
            v-for="(option, idx) in filteredOptions"
            :key="`${filter.title}-option-${idx}`"
            class="filterdropdown-content-items-item"
            :class="{
              selected: selection.includes(option.id || option.key),
              disabled:
                selection.length &&
                !selection.includes(option.id || option.key) &&
                !filter.multiselect
            }"
            :title="option.value || option.key || emptyText"
            @click="() => selectOption(option.id || option.key)"
          >
            <Checkbox
              :value="selection.includes(option.id || option.key)"
              class="filterdropdown-content-items-item-checkbox"
            />
            <slot name="item" :item="option">
              <span class="filterdropdown-content-items-item-text">{{
                filter.capitalize
                  ? (option.value || option.key || emptyText).capitalize()
                  : option.value || option.key || emptyText
              }}</span>
            </slot>
            <Tag
              v-if="option.count"
              :text="`${option.count}`"
              type="light-solid"
              size="xxs"
              class="filterdropdown-content-items-item-count"
            />
          </div>
        </div>
        <div class="filterdropdown-content-footer">
          <div class="filterdropdown-content-footer-btns">
            <div
              v-if="filter.multiselect"
              class="filterdropdown-content-footer-selectall"
              @click="handleSelectAll"
            >
              <Checkbox
                :value="selection.length === filter.options.length"
                :indeterminate="
                  !!selection.length &&
                  selection.length !== filter.options.length
                "
                class="filterdropdown-content-footer-selectall-check"
              />
              {{
                `${
                  selection.length === filter.options.length ? 'de' : ''
                }select all`.capitalize()
              }}
            </div>
            <Button
              v-else
              v-show="selection.length"
              text="Clear"
              type="light"
              @click="handleClear"
            />
          </div>
          <div class="filterdropdown-content-footer-btns">
            <Button
              text="Apply"
              class="filterdropdown-content-footer-apply"
              @click="submit"
            />
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: 'FilterDropdown',
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Array,
      default: () => []
    },
    maxHeight: {
      type: String,
      default: '15rem'
    },
    emptyText: {
      type: String,
      default: ''
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    equalWidth: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit'],
  data: () => ({
    selection: [],
    query: ''
  }),
  computed: {
    filteredOptions() {
      return this.filter.options.filter((option) =>
        (option.value || option.key || this.emptyText)
          .toLowerCase()
          .includes(this.query.toLowerCase())
      )
    }
  },
  watch: {
    selected: {
      handler() {
        this.setSelection()
      },
      deep: true
    }
  },
  created() {
    this.setSelection()
  },
  methods: {
    setSelection() {
      this.selection = [...this.selected]
    },
    selectOption(option) {
      if (this.selection.includes(option)) {
        this.selection = this.selection.filter((item) => item !== option)
      } else {
        this.selection.push(option)
      }
    },
    handleClear() {
      this.selection = []
    },
    handleSelectAll() {
      if (this.selection.length === this.filter.options.length) {
        this.selection = []
      } else {
        this.selection = this.filter.options.map(
          (option) => option.id || option.key
        )
      }
    },
    submit() {
      this.$emit('submit', this.selection)
      this.$refs.filterdropdown.hideOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
.filterdropdown {
  &-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid $border-color;
    user-select: none;

    &.equalized {
      min-width: 10rem;
      margin-left: auto;
    }

    &-count {
      padding: 0 0.5rem;
    }

    &-chevron {
      height: 1.2rem;
      margin-left: auto;
    }
  }

  &-content {
    background: $white;
    border-radius: 4px;
    border: 1px solid $border-color;
    width: 20rem;
    padding-top: 0.5rem;
    user-select: none;

    &-header {
      padding: 0.5rem 0.75rem;
    }

    &-items {
      max-height: var(--dropdown-content-height);
      overflow-y: auto;

      &-item {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.5rem 0.75rem;
        gap: 1rem;
        cursor: pointer;
        user-select: none;

        &.selected {
          background: $border-color;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &:hover {
          background: rgba($black, 0.04);
        }

        &-checkbox {
          pointer-events: none;
        }

        &-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-count {
          margin-left: auto;
        }
      }
    }

    &-footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
      border-top: 1px solid $border-color;
      justify-content: space-between;

      &-btns {
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;
      }

      &-selectall {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        &-check {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
