export function validateURL(url) {
  if (!url) return -1

  let u = null
  try {
    u = new URL(url)
  } catch (e) {
    return 0
  }

  if (!u) return 0

  const pattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  return pattern.test(url) ? 1 : 0
}
