export function roundTo(num, round_to = 1) {
  return Number.parseFloat(num.toFixed(round_to))
}

export function formatNumber(number, threshold = 1e3, round_to = 1) {
  if (typeof number !== 'number') return ''
  if (number < threshold) return `${number}`
  if (number < 1e6) return `${roundTo(number / 1e3, round_to)}K`
  return `${roundTo(number / 1e6, round_to)}M`
}
