<template>
  <div class="sidebar">
    <div class="sidebar-links">
      <Tooltip
        v-for="item in sidebarContent"
        :key="item.label"
        :text="item.label"
        :active="!showWorkspaceList"
        position="right"
      >
        <router-link :to="item.route">
          <div
            class="sidebar-links-link"
            :class="{
              selected: item.routeNames.includes($route.name),
              'is-home':
                item.routeNames.includes('home') &&
                currentWorkspace.image &&
                !homeErrored &&
                homeLoaded
            }"
            @click="item.click"
            @contextmenu="(e) => handleRightClick(item, e)"
          >
            <img
              v-if="
                item.routeNames.includes('home') &&
                currentWorkspace.image &&
                !homeErrored
              "
              :src="currentWorkspace.image"
              alt=""
              class="sidebar-links-link-icon"
              @error="() => handleHomeError(item)"
              @load="() => handleHomeLoad(item)"
            />
            <Icon
              v-else
              :name="item.icon"
              :fill="item.routeNames.includes($route.name) ? 'primary' : 'dark'"
              class="sidebar-links-link-icon"
            />
            <p v-if="item.badge" class="sidebar-links-link-badge">
              {{ item.badge }}
            </p>
          </div>
        </router-link>
      </Tooltip>
      <div class="sidebar-links-bottom">
        <SidebarImpersonationMenu v-if="isUman" />
        <SidebarTaskQueue />
        <Tooltip
          v-if="showReferral"
          text="Discover our referral program"
          position="right"
        >
          <div class="sidebar-links-link">
            <a href="https://pages.uman.ai/referral-program" target="_blank">
              <Icon name="present" class="sidebar-links-link-icon" />
            </a>
            <p class="sidebar-links-link-badge">NEW</p>
          </div>
        </Tooltip>
        <SidebarUserMenu />
      </div>
    </div>
    <WorkspaceSelect
      v-if="showWorkspaceList"
      @hide="() => (showWorkspaceList = false)"
      @create="openCreateWorkspaceModal"
    />
  </div>
  <CreateWorkspaceModal
    v-if="showCreateWorkspaceModal"
    :visible="showCreateWorkspaceModal"
    @close="closeCreateWorkspaceModal"
  />
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { computed, ref } from 'vue'
import { useSettingsStore } from '@/stores/settings'
import { useWorkspaceStore } from '@/stores/workspace'
import { useViewAuth } from '@/core/routes'

import SidebarUserMenu from '@c/core/sidebar/SidebarUserMenu.vue'
import WorkspaceSelect from '@c/core/sidebar/WorkspaceSelect.vue'
import SidebarImpersonationMenu from '@c/core/sidebar/SidebarImpersonationMenu.vue'
import CreateWorkspaceModal from './sidebar/CreateWorkspaceModal.vue'
import SidebarTaskQueue from './sidebar/SidebarTaskQueue.vue'

// Checklist for adding a new view:
// - [ ] Create a component in the views folder
// - [ ] Create an entry in the router index using that component
// - [ ] Add an entry with the required user permissions in the core/routes.js file
// - [ ] Add an entry in GlobalSidebar
// - [ ] Add a header in GlobalHeader

const userStore = useUserStore()
const settingsStore = useSettingsStore()
const workspaceStore = useWorkspaceStore()

const { currentWorkspace, showReferral } = storeToRefs(workspaceStore)
const { isUman } = storeToRefs(userStore)

const { availableRoutes } = useViewAuth()

const sidebarContent = computed(() => {
  const params = {
    params: {
      workspace_id: currentWorkspace.value?.uuid
    }
  }
  const routes = {
    home: {
      label: 'Home',
      icon: 'home-alt',
      routeNames: ['home'],
      route: {
        name: 'home',
        ...params,
        query: {}
      }
    },
    search: {
      label: 'Search',
      icon: 'search',
      routeNames: ['search'],
      route: { name: 'search', ...params }
    },
    meetings: {
      label: 'Your meetings',
      icon: 'calendar',
      routeNames: ['meetings', 'meeting-outputs'],
      route: { name: 'meetings', meeting_id: undefined, ...params }
    },
    chat: {
      label: 'PortfolioGPT',
      icon: 'chat',
      routeNames: ['chat'],
      route: { name: 'chat', ...params }
    },
    portfolio: {
      label: 'Portfolio',
      icon: 'compass',
      routeNames: ['portfolio', 'portfolio-detail'],
      route: {
        name: 'portfolio',
        params: {
          workspace_id: currentWorkspace?.value?.uuid,
          ori_type: 'offerings',
          ori_id: undefined
        }
      }
    },
    // prospecting: {
    //   label: 'Prospecting',
    //   icon: 'prospect',
    //   routeNames: ['prospecting'],
    //   route: { name: 'prospecting', ...params }
    // },
    bizdev: {
      label: 'Business Development',
      icon: 'prospect',
      routeNames: ['bizdev'],
      route: { name: 'bizdev', ...params }
    },
    // lists: {
    //   label: 'Account lists',
    //   icon: 'checklist',
    //   routeNames: ['lists'],
    //   route: { name: 'lists', ...params }
    // },
    presentations: {
      label: 'Your presentations',
      icon: 'presentation',
      routeNames: ['presentations'],
      route: { name: 'presentations', ...params }
    },
    analytics: {
      label: 'Analytics',
      icon: 'pie-chart',
      routeNames: ['analytics'],
      route: { name: 'analytics', ...params }
    },
    curation: {
      label: 'Curation items',
      icon: 'curate',
      routeNames: ['curation'],
      route: { name: 'curation', ...params }
    },
    settings: {
      label: 'Workspace settings',
      icon: 'settings',
      routeNames: ['settings'],
      route: {
        name: 'settings',
        params: {
          workspace_id: currentWorkspace.value?.uuid,
          tab: settingsStore.settingsTabs[0]
        }
      }
    }
  }

  return Object.keys(routes)
    .filter((r) => availableRoutes.value.includes(r))
    .map((r) => routes[r])
})

const homeLoaded = ref(false)
const homeErrored = ref(false)
const showWorkspaceList = ref(false)

const showCreateWorkspaceModal = ref(false)

function handleHomeError(item) {
  homeErrored.value = true
}

function handleHomeLoad(item) {
  homeLoaded.value = true
}

function handleRightClick(item, event) {
  if (
    !(
      item.routeNames.includes('home') &&
      isUman.value &&
      workspaceStore.workspaces?.length
    )
  )
    return
  event.preventDefault()
  if (showWorkspaceList.value) {
    showWorkspaceList.value = false
    return
  }
  showWorkspaceList.value = true
}

function openCreateWorkspaceModal() {
  showCreateWorkspaceModal.value = true
  showWorkspaceList.value = false
}

function closeCreateWorkspaceModal() {
  showCreateWorkspaceModal.value = false
}
</script>

<style scoped lang="scss">
.sidebar {
  height: 100vh;
  width: $sidebar-width;
  min-width: $sidebar-width;
  background: $white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-right: 2px solid $grey-200;

  &-links {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    flex: 1;
    padding: 1rem 0;
    align-items: center;

    &-link {
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;

      &:hover {
        background: $grey-200;
      }

      &.is-home {
        &.selected {
          border: 1px solid $primary;
          background: rgba($primary, 0.1);
        }

        & > .sidebar-links-link-icon {
          border-radius: 2px;
        }
      }

      &-icon {
        height: 1.5rem;
      }

      &-badge {
        position: absolute;
        top: 0;
        right: 10%;
        transform: translateY(-50%) translateX(50%);
        font-size: 0.75rem;
        font-weight: 600;
        color: $white;
        background: $red;
        border: 2px solid $white;
        border-radius: 999rem;
        padding: 0 0.25rem;
        white-space: nowrap;
      }
    }

    &-bottom {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      align-items: center;
      margin-top: auto;
    }
  }
}
</style>
