<template>
  <div
    ref="tooltipcontainer"
    class="tooltip"
    :class="{ active, 'full-height': fullHeight, 'full-width': fullWidth }"
  >
    <div
      class="tooltip-trigger"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <slot name="default"></slot>
    </div>
    <Teleport to="body" :disabled="!(appendToBody && hovered)">
      <div
        class="tooltip-content"
        :class="{ [position]: true, multiline, active }"
        :style="calculatedStyle"
      >
        <slot name="left"></slot>
        {{ text }}
        <slot name="right"></slot>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  text: {
    type: String,
    required: true
  },

  position: {
    type: String,
    default: 'top',
    validator: (value) => {
      return [
        'top',
        'bottom',
        'left',
        'right',
        'topleft',
        'topright',
        'bottomleft',
        'bottomright'
      ].includes(value)
    }
  },

  active: {
    type: Boolean,
    default: true
  },

  appendToBody: {
    type: Boolean,
    default: false
  },

  multiline: {
    type: Boolean,
    default: false
  },

  fullHeight: {
    type: Boolean,
    default: false
  },

  fullWidth: {
    type: Boolean,
    default: false
  }
})

const hovered = ref(false)

const calculatedStyle = ref({})

const tooltipcontainer = ref(null)

function calculateStyle() {
  if (!(tooltipcontainer.value && props.appendToBody)) return
  const containerRect = tooltipcontainer.value.getBoundingClientRect()
  const bodyRect = document.body.getBoundingClientRect()
  const position = props.position
  calculatedStyle.value = {
    ...(['top', 'topleft', 'topright'].includes(position)
      ? { bottom: `${bodyRect.height - containerRect.top + 5}px` }
      : {}),
    ...(['bottom', 'bottomleft', 'bottomright'].includes(position)
      ? { top: `${containerRect.bottom + 5}px` }
      : {}),
    ...(position === 'left'
      ? { right: `${bodyRect.width - containerRect.left + 5}px` }
      : {}),
    ...(position === 'right' ? { left: `${containerRect.right + 5}px` } : {}),
    ...(['left', 'right'].includes(position)
      ? {
          top: `${containerRect.top + containerRect.height / 2}px`,
          transform: 'translateY(-50%)'
        }
      : {
          ...(['bottomright', 'topright', 'bottom', 'top'].includes(position)
            ? {
                left: `${containerRect.left + containerRect.width / 2 - (['bottom', 'top'].includes(position) ? 0 : 10)}px`
              }
            : {
                right: `${containerRect.right - containerRect.width / 2 - 10}px`
              }),
          ...(['bottom', 'top'].includes(position)
            ? {
                transform: 'translateX(-50%)'
              }
            : {})
        }),
    opacity: 1,
    width: 'unset',
    maxWidth: 'unset',
    height: 'unset',
    maxHeight: 'unset',
    overflow: 'unset',
    pointerEvents: 'auto',
    padding: '0.5rem 0.75rem'
  }
}

function removeStyle() {
  calculatedStyle.value = {}
}

function handleMouseEnter() {
  if (!props.active) return
  hovered.value = true
  calculateStyle()
}

function handleMouseLeave() {
  hovered.value = false
  removeStyle()
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  width: fit-content;

  &.full-width {
    width: 100%;
  }

  &-content {
    opacity: 0;
    width: 0;
    max-width: 0;
    height: 0;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;
    transition:
      opacity 0.2s ease,
      padding 0.2s ease;
    position: absolute;
    z-index: 99;
    background: $dark;
    color: $white;
    padding: 0;
    border-radius: 6px;
    font-size: 0.85rem;
    box-shadow: 0 1px 2px 1px rgba($dark, 0.2);
    white-space: nowrap;

    &.top {
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);

      &::before {
        border-width: 5px 5px 0 5px;
        border-color: $dark transparent transparent transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.bottom {
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);

      &::before {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $dark transparent;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.left {
      top: 50%;
      right: calc(100% + 5px);
      transform: translateY(-50%);

      &::before {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent $dark;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
      }
    }

    &.right {
      top: 50%;
      left: calc(100% + 5px);
      transform: translateY(-50%);

      &::before {
        border-width: 5px 5px 5px 0;
        border-color: transparent $dark transparent transparent;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
      }
    }

    &.topleft {
      bottom: calc(100% + 5px);
      right: calc(50% - 10px);

      &::before {
        border-width: 5px 5px 0 5px;
        border-color: $dark transparent transparent transparent;
        top: 100%;
        right: 5px;
      }
    }

    &.topright {
      bottom: calc(100% + 5px);
      left: calc(50% - 10px);

      &::before {
        border-width: 5px 5px 0 5px;
        border-color: $dark transparent transparent transparent;
        top: 100%;
        left: 5px;
      }
    }

    &.bottomleft {
      top: calc(100% + 5px);
      right: calc(50% - 10px);

      &::before {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $dark transparent;
        bottom: 100%;
        right: 5px;
      }
    }

    &.bottomright {
      top: calc(100% + 5px);
      left: calc(50% - 10px);

      &::before {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $dark transparent;
        bottom: 100%;
        left: 5px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &.multiline {
      white-space: normal;
    }
  }

  &.active {
    &:hover {
      .tooltip-content {
        opacity: 1;
        width: unset;
        max-width: unset;
        height: unset;
        max-height: unset;
        overflow: unset;
        pointer-events: none;
        padding: 0.5rem 0.75rem;

        &.multiline {
          width: 17.5rem;
        }
      }
    }
  }

  &.full-height {
    height: 100%;
    & .tooltip-trigger {
      height: 100%;
    }
  }
}
</style>
